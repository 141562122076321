// import { actionType } from "action/ActivityThree";

// const initialState = {
//     data: [
//         {
//             formData: "",
//             image: require('assets/images/storyThree-1.png')
//         },
//         {
//             formData: "",
//             image: require('assets/images/storyThree-2.png')
//         },
//         {
//             formData: "",
//             image: require('assets/images/storyThree-3.png')
//         },
//         {
//             formData: "",
//             image: require('assets/images/storyThree-4.png')
//         },
//     ]
// }

// export default function activityThree(state = initialState, action){
//     switch (action.type){
//         case actionType.UPDATE_DATA:
//             console.log(action.data)
//             return {
//                 ...state,
//                 data: action.data
//             }
//         default:
//             return state;
//     }
// }
import { activityThree } from "service/actionType";

const initialState = {
  formOne: {},
  formTwo: {},
};
const activityThreeReducer = (state = initialState, action) => {
  switch (action.type) {
    case activityThree.formOne:
      return {
        ...state,
        formOne: action.payload,
      };
    case activityThree.formTwo:
      return {
        ...state,
        formTwo: action.payload,
      };
    default:
      return state;
  }
};

export default activityThreeReducer;
