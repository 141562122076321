import React, { Component } from "react";


export class LoginLayout extends Component {
  render() {
    let { children } = this.props;

    return (
      <div className="container-fluid login-layout vh-100">
          {children}
      </div>
    );
  }
}
