import { activityOne } from "service/actionType";
import { activityApi } from "../service/apiVariables";

let userEmail = localStorage.getItem("userEmail");
export const activityOneFormOne = (data) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: activityOne.formOne,
      payload: data,
    });
  });
};

export const activityOneFormThree = (data) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: activityOne.formThree,
      payload: data,
    });
  });
};

export const activityOneFormFive = (data) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: activityOne.formFive,
      payload: data,
    });
  });
};

export const activityOneFormSix = (data) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: activityOne.formSix,
      payload: data,
    });
  });
};

export const activityOneFormSeven = (data) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: activityOne.formSeven,
      payload: data,
    });
  });
};

export const activityOneFormNine = (data) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: activityOne.formNine,
      payload: data,
    });
  });
};

export const activityOneFormEleven =
  (data) =>
  (dispatch, getState, { api }) => {
    return new Promise((resolve, reject) => {
      let body = {
        adminEmail:
          "avinesh.k@doodleblue.com,nishants440@gmail.com,shreesenthil11@gmail.com,mariadelmar.ribas@yahoo.fr,lferre@althaia.cat",
        subject: "story-1",
        userId: userEmail,
        result: getState().activityOneReducer,
      };
      api({ ...activityApi.sendData, body })
        .then((data) => {
          console.log(data);
        })
        .catch((message) => {
          console.log(message);
        });
      dispatch({
        type: activityOne.formEleven,
        payload: data,
      });
    });
  };
