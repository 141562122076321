import { closureOne } from "service/actionType";

const initialState = {
  closureFormOne: {},
  closureFormTwo: {},
  closureFormThree: {},
  closureFormFour: {},
  closureFormFive: {},
  closureFormSix: {},
  closureFormSeven: {},
  closureFormEight: {},
  closureFormNine: {},
  closureFormEleven: {},
};

const closureOneReducer = (state = initialState, action) => {
  switch (action.type) {
    case closureOne.closureFormOne:
      return {
        ...state,
        closureFormOne: action.payload,
      };
    case closureOne.closureFormTwo:
      return {
        ...state,
        closureFormTwo: action.payload,
      };
    case closureOne.closureFormThree:
      return {
        ...state,
        closureFormThree: action.payload,
      };
    case closureOne.closureFormFour:
      return {
        ...state,
        closureFormFour: action.payload,
      };
      case closureOne.closureFormFive:
      return {
        ...state,
        closureFormFive: action.payload,
      };
      case closureOne.closureFormSix:
      return {
        ...state,
        closureFormSix: action.payload,
      };
      case closureOne.closureFormSeven:
      return {
        ...state,
        closureFormSeven: action.payload,
      };
      case closureOne.closureFormEight:
      return {
        ...state,
        closureFormEight: action.payload,
      };
      case closureOne.closureFormNine:
      return {
        ...state,
        closureFormNine: action.payload,
      };
      case closureOne.closureFormEleven:
      return {
        ...state,
        closureFormEleven: action.payload,
      };
    default:
      return state;
  }
};

export default closureOneReducer;
