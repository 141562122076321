import { IntroActivityOne } from "service/actionType";
import { activityApi } from "../service/apiVariables";

let userEmail = localStorage.getItem("userEmail");

export const IntroActivityOneFormOne =
  (data) =>
  (dispatch, getState, { api }) => {
    return new Promise((resolve, reject) => {
      let body = {
        adminEmail:
          "avinesh.k@doodleblue.com,nishants440@gmail.com,shreesenthil11@gmail.com,mariadelmar.ribas@yahoo.fr,lferre@althaia.cat",
        subject: "IntroActvityOne",
        userId: userEmail,
        result: {
          formOne: data,
        },
      };
      api({ ...activityApi.sendData, body })
        .then((data) => {
          console.log(data);
        })
        .catch((message) => {
          console.log(message);
        });
      dispatch({
        type: IntroActivityOne.formOne,
        payload: data,
      });
    });
  };
