import React, { useState } from "react";
import Draggable from "react-draggable";
import './Style/IntroActivityTwo.scss'
import { introTwoItems, introTwoItemsDestination } from '../common/IntroActivityTwoArray/index';
import IntroActvityNav from "../common/IntroActivityNav/IntroActivitYNav"
import HeadingText from "./Images/INTRODUCCIÓN_EMOCIONES.png"
import Text from "./Images/Text.png"
import TextIcon from "./Images/TextIcon2.png"
import smallStar from './Images/smallstar.png';
import outlineStar from './Images/outlineStar.png';
export function IntroActivityTest() {
    const [activeFace, setActiveFace] = useState(null)
    const [leftEmotion, setLeftEmotion] = useState(null)
    const [rightEmotion, setRightEmotion] = useState(null)
    const [corretFaces, setCorrectFaces] = useState(introTwoItemsDestination);
    const [activeDrags, setActiveDrags] = useState(0);
    const [deltaPosition, setDeltaPosition] = useState({
        x: 0,
        y: 0
    });
    const [controlledPosition, setControlledPosition] = useState({
        x: 0,
        y: 0
    });

    const handleDrag = (e, ui) => {
        const { x, y } = deltaPosition;
        setDeltaPosition({
            x: x + ui.deltaX,
            y: y + ui.deltaY
        })
    }

    const onStart = () => {
        setActiveDrags(activeDrags + 1)
    }

    const onStop = () => {
        setActiveDrags(activeDrags - 1)
    }
    // For controlled component
    const adjustXPos = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { x, y } = controlledPosition;
        setControlledPosition({ controlledPosition: { x: x - 10, y } })
    }

    const adjustYPos = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { controlledPosition } = this.state;
        const { x, y } = controlledPosition;
        this.setState({ controlledPosition: { x, y: y - 10 } });
    }
    const onControlledDrag = (e, position) => {
        const { x, y } = position;
        this.setState({ controlledPosition: { x, y } });
    }

    const onControlledDragStop = (e, position) => {
        this.onControlledDrag(e, position);
        this.onStop();
    }

    const dragHandlers = { onStart: onStart, onStop: onStop };


    const handleLeftFace = (id, emotion) => {
        setActiveFace(id)
        setLeftEmotion(emotion)
    }
    const handleRightFace = (emotion) => {
        if (leftEmotion === emotion) {
            setCorrectFaces(prevItem => {
                return prevItem.map(item => {
                    if(item.emotion === emotion){
                        return {...item, matched: true}
                    }
                    else{
                        return item
                    }
                })
            })
        }
        else{
            console.log('Not Matched')
        }
    }
    console.log(corretFaces)
    const handleReset = () => {
        setCorrectFaces([]);
        setLeftEmotion(null)
        setRightEmotion(null)
        setActiveFace(null)
    }

    return (
        <div>

            <div className="Background">
                <IntroActvityNav
                    InProgressCar="35%"
                    InProgress="40%"
                    TextIcon={TextIcon}
                ></IntroActvityNav>

                <div className="row text-center">
                    <div className="col-md-3"></div>
                    <div className="col-md-7">
                        <img src={HeadingText} ></img>
                    </div>
                    <div className="col-md-3"></div>
                </div>

                <div className="row text-center mt-5">
                    <div className="col-md-3"></div>
                    <div className="col-md-7">
                        <img className="TextImg" src={Text} ></img>
                    </div>
                    <div className="col-md-3"></div>
                </div>


                {/* -----------Parent-Row----------------- */}

                <div className="row mt-5 centered-card">

                    <div className="col-md-2"></div>
                    <div className="grid-six Card">
                        {introTwoItems.map((faces, idx) => {
                            return (
                                <div className={corretFaces?.find(val => val === faces.emotion) ? "disappear" : "face-cont"} id={idx} key={idx}>
                                    <img onClick={() => { handleLeftFace(idx, faces?.emotion) }} className={activeFace === idx ? "face-image-selected" : "face-image"} src={faces.image} />
                                </div>
                            )
                        })}
                    </div>
                    <div className="col-md-5 ml-5">
                        <div className="grid-three-alt">
                            {corretFaces.map((faces, idx) => {
                                return (
                                    <div className="desstination-face-cont" id={idx} key={idx}>
                                        <img src={faces.matched ? smallStar : outlineStar} className="small-star"/>
                                        <img onClick={() => { handleRightFace(faces?.emotion) }} className="face-image-destination" src={faces.matched ? faces.correctImage : faces.image} />
                                        <p className="emotion-names">{faces.emotion}</p>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="col-md-1"></div>
                </div>
                <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-8"></div>
                    <div className="col-md-2">
                        <div className="FooterFlexButton">
                            <button onClick={handleReset} type="button" class="btn btn-primary btn-lg footerBtn">Reset</button>
                            <button type="button" class="btn btn-primary btn-lg footerBtn">click Me</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
