import React, { useState } from "react";
import "./Style/ActivityOne.scss";
// import "../IntroActivity/Style/IntroActivityOne.css"
import { Progressbar } from "../common/ProgressBar/ProgressBar";
import { Closurebutton } from "component/common/Closurebutton";
import { ArrowLeft } from "react-bootstrap-icons";
import { FaBeer } from "react-icons/fa";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { AiFillHome } from "react-icons/ai";
// import {AiFillHome} from "react-bootstrap-icons"
import rectangleImg from "../IntroActivity/Images/Rectangle 2803.png";
import ScrollingImg from "../IntroActivity/Images/image 30 (1).png";
import StarIcon from "../IntroActivity/Images/Vector (4).png";
import TextIcon from "../IntroActivity/Images/5.png";
import Box from "../IntroActivity/Images/Vector (5).png";
import HeadingText from "../IntroActivity/Images/Activity1HeadingText.png";
import text from "../IntroActivity/Images/ActivityOneText.png";
import BoxImage from "../IntroActivity/Images/HomeBox.png";
import ChatIcon from "../IntroActivity/Images/chaticon.png";
import leftChatIcon from "../IntroActivity/Images/leftChatIcon.png";
import ChatboxOne from "../common/ChatBox/ChatboxOne";
import ChatboxTwo from "../common/ChatBox/ChatboxTwo";
import ChatboxThree from "../common/ChatBox/ChatboxThree";
import { useDispatch, useSelector } from "react-redux";
import { activityOneFormOne } from "action/activityOneAct";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { sendActivityData } from 'action/activityAct';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import EditIcon from "assets/images/EditIcon.png";

export const ActivityOne = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  let userEmail = localStorage.getItem('userEmail')
  const [formData, setFormData] = useState({
    fieldOne: "",
    fieldTwo: "",
    fieldThree: ""
  });

  toast.configure();
  const notify = () => {
    // Calling toast method by passing string
    toast("datos de campo requeridos");
  };

  // const successData = () => {
  //   // Calling toast method by passing string
  //   toast("éxito");
  // };

  const handleSubmit = () => {
    dispatch(activityOneFormOne(formData));
    if (formData.fieldOne === "") {
      notify();
    } else if (formData.fieldTwo === "") {
      notify();
    } else if (formData.fieldThree === "") {
      notify();
    } else {
      // successData();
      // props.sendActivityData(body)
      //   .then((response) => {
      //     toast.success(response.message, {
      //       autoClose: 2000
      //     })
      //   })
      //   .catch(err => {
      //     console.log(err)
      //   })
      history.push("/story-one/activity2");
    }
  };

  const handleChangeValue = (e) => {
    const { name, value } = e.target || e || {};
    setFormData({ ...formData, [name]: value });
  };
  let { fieldOne, fieldTwo, fieldThree } = formData;
  return (
    <>
      <div className="">
        <div className="row text-center pt-4">
          <ToastContainer position="top-center" />

          <div className="col-md-2">
          </div>
          <div className="col-md-8 mt-4">
          </div>
          <div className="col-md-2">
          </div>
        </div>
        <div className="row text-center mt-3 pb-3">
          <div className="col-md-4 ml-5">
            <h1 className=" Activity-heading-text">
              Aprendemos con Alicia
            </h1>
          </div>
        </div>

        <div className="row mt-1 ">
          <div className="col-md-0"></div>
          <div className="col-md-10 ml-5">
            <p className="activity-paragraph">
              ¿Qué crees que están pensando los otros ratoncitos?{" "}
              <span className="special-text">Escríbelo en las nubes.</span>
            </p>
          </div>
          <div className="col-md-2"></div>
        </div>

        <div className="row mt-3 text-center">
          <div className="col-md-3"></div>
          <div className="col-md-7 text-center BoxCol mt-3">
            <img className="BoxImage" src={BoxImage}></img>
            <div className="chatIcon">
              <ChatboxOne
                name="fieldOne"
                fieldValue={fieldOne}
                handleChange={handleChangeValue}
              />
            </div>
            <div className="chatIcon-one">
              <ChatboxTwo
                name="fieldTwo"
                fieldValue={fieldTwo}
                handleChange={handleChangeValue}
              />
            </div>

            <div className="leftChatIcon">
              <ChatboxThree
                name="fieldThree"
                fieldValue={fieldThree}
                handleChange={handleChangeValue}
                left="5%"
              />
            </div>
          </div>
          <div className="col-md-2"></div>
        </div>
        <div className="row w-100">
          <div className="col py-4">
            <div className="d-flex justify-content-end pr-5">
              <div className="actone-topward">
                <Closurebutton onClick={handleSubmit}>
                  <span className="actvity-one-hecho-validator">hecho</span>
                </Closurebutton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};


const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ sendActivityData: sendActivityData }, dispatch);
};

export const ActivityOneComp = connect(null, mapDispatchToProps)(ActivityOne);