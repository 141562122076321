import { activityTwo } from "service/actionType";

const initialState = {
  formOne: {},
  formTwo: {},
  formThree: {},
};
const activityTwoReducer = (state = initialState, action) => {
  switch (action.type) {
    case activityTwo.formOne:
      return {
        ...state,
        formOne: action.payload,
      };


    case activityTwo.formTwo:
      return {
        ...state,
        formTwo: action.payload,
      };

      case activityTwo.formThree:
      return {
        ...state,
        formThree: action.payload,
      };

      case activityTwo.formFive:
      return {
        ...state,
        formFive: action.payload,
      };

      case activityTwo.formSix:
        return {
          ...state,
          formSix: action.payload,
        };
        case activityTwo.formSeven:
          return {
            ...state,
            formSeven: action.payload,
          };
           
          case activityTwo.formTen:
            return {
              ...state,
              formTen: action.payload,
            };
          case activityTwo.formEleven:
          return {
            ...state,
            formEleven: action.payload,
          };

          case activityTwo.formThirteen:
            return {
              ...state,
              formThirteen: action.payload,
            };

            case activityTwo.formFourteen:
              return {
                ...state,
                formFourteen: action.payload,
              };

              case activityTwo.formFifteen:
                return {
                  ...state,
                  formFifteen: action.payload,
                };
    default:
      return state;
  }
};

export default activityTwoReducer;
