/* eslint-disable react/react-in-jsx-scope */
import React from "react";
import BootstrapButton from "react-bootstrap/Button";
import "./Logout.scss";

/**
 * Button: The Common Re-usable Button across website.
 * @return {JSX.Element} The JSX Code for Button
 */

export function Logoutbtn({ children, onClick }) {
  return (
    <BootstrapButton
      id="logoutbtns"
      className="mx-auto d-block w-100"
      onClick={onClick}
    >
      {children}
    </BootstrapButton>
  );
}
