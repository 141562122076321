import React, { useEffect } from "react";
import "./Style/IntroActivityFour.scss";
import IntroActvityNav from "../common/IntroActivityNav/IntroActivitYNav";
import { Closurebutton } from "component/common/Closurebutton";
import VerticalLine from "./Images/RectangleVertical.png";
// import AnimalImg from "./Images/IntroAct4.png"
import firstDot from "../../assets/images/first-dot.png";
import secondDot from "../../assets/images/second-dot.png";
import AnimalImg from "./Images/IntroAct4.png";
import AnimalImg1 from "./Images/AnimalFivTen.svg";
import AnimalImg2 from "./Images/AnimalTenFif.svg";
import AnimalImg3 from "./Images/AnimalFifTwenty.svg";
import HeadingText from "./Images/FELICIDAD.png";
import TextIcon4 from "./Images/TextIcon4.png";
import RangeSlider from "component/common/RangeSlider/RangeSlider";
import Marking from "component/common/ImageMarker/Marker";
import { useDispatch, useSelector } from "react-redux";
import { setRangeSlider } from "action/activityAct";
import { history } from "service/helpers";

export const IntroActivityFour = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setRangeSlider(25));
  }, []);
  const rangeSliderVal = useSelector(
    (store) => store?.commonStore?.rangeSlider
  );

  const GirroffeImg = () => {
    if (rangeSliderVal > 27 && rangeSliderVal <= 55) {
      return (
        <>
          <img className="AnimalImg-IntroActivity4" src={AnimalImg1}></img>
        </>
      );
    } else if (rangeSliderVal > 55 && rangeSliderVal <= 75) {
      return (
        <>
          <img className="AnimalImg-IntroActivity4" src={AnimalImg2}></img>
        </>
      );
    } else if (rangeSliderVal > 75) {
      return (
        <>
          <img className="AnimalImg-IntroActivity4" src={AnimalImg3}></img>
        </>
      );
    } else {
      return (
        <>
          <img className="AnimalImg-IntroActivity4" src={AnimalImg}></img>
        </>
      );
    }
  };
  return (
    <>
      <div className="IntroBackground IntroActivity4 ">
        <IntroActvityNav
          InProgressCar="75%"
          InProgress="80%"
          TextIcon={TextIcon4}
        />

        <div className="row mt-5 pb-2">
          <div className="col-md-2"></div>
          <div className="col-md-3 silderColumn">
            <RangeSlider className="VerticalLine" />
            {GirroffeImg()}
          </div>
          <div className="col-md-5">
            <h1 className="Intro-three-headingText">FELICIDAD</h1>

            <p className="IntroText pt-4">
              ¿Cómo de contento te sentiste? Márcalo en la jirafa.
            </p>
          </div>
          <div className="col-md-2"></div>
        </div>

        {/* <div className="row text-center">
                    <div className="col-md-2"></div>
                    <div className="col-md-8"></div>
                    <div className="col-md-2 mt-0 FooterFlexButton pb-2">
                        <div className='btn-outer-border IntroActivity-footer-btn'>
                            <div className="btn-bg">
                                <img className='first-line' src={firstDot}></img>
                                <img className='second-line' src={secondDot}></img>
                                <button type="button" className="footerButton btn btn-info" onClick={() => history.push('/IntroActivity5')} >HECHO</button>
                            </div>
                        </div>
                    </div>
                </div> */}

        <div className="row w-100">
          <div className="col py-4">
            <div className="d-flex justify-content-end pr-5">
              <div className="Introfour-topward">
              <Closurebutton  onClick={() => history.push("/IntroActivity5")}>
                <span className="introact-four-hecho-validator px-4">hecho</span>
              </Closurebutton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
