const routers = [
    {
        path: "/",
        redirect: "/login",
        auth: false,
    },
    {
        component: "LoginLayout",
        path: "/login",
        auth: true,
        name: "LoginLayout",
        exact: false,
        childrens: [
            {
                component: "LoginPage",
                path: "/",
                componentPath: "pages/LoginPages/LoginPage",
                name: "LoginPage",
                auth: true,
                exact: true,
            },
        ],
    },
    {
        component: "Dashboard",
        path: "/Dashboard",
        auth: false,
        name: "Dash",
        exact: false,
        redirect: "/Dashboard",
        childrens: [
            {
                component: "DashboardPage",
                path: "/dashboard",
                componentPath: "/DashboardPage/DashboardPage",
                name: "DashboardPage",
                auth: false,
                exact: true,
            },
        ],
    },
    {
        component: "StoryLayout",
        path: "/story-three",
        auth: true,
        name: "StoryLayout",
        exact: false,
        childrens: [
            {
                component: "StoryThreePage",
                path: "/",
                componentPath: "pages/StoryPage/StoryThreePage",
                name: "StoryThreePage",
                auth: true,
                exact: true,
            },
            {
                component: "Story3",
                path: "/activity-Three",
                componentPath: "pages/StoryPage/Story3",
                name: "Story3",
                auth: true,
                exact: true
            },
            {
                component: "Story6",
                path: "/activity-Six",
                componentPath: "pages/StoryPage/Story6",
                name: "Story6",
                auth: true,
                exact: true
            }
        ],
    },
    {
        component: "StoryLayout",
        path: "/story-two",
        auth: true,
        name: "StoryLayout",
        exact: false,

        childrens: [
            {
                component: "StorytwoPage",
                path: "/",
                componentPath: "pages/StoryPage/StoryTwoPage",
                name: "StorytwoPage",
                auth: true,
                exact: true,
            },
            {
                component: "ATOne",
                path: "/activity1",
                componentPath: "pages/StoryPage/Activity/Two/ATOne",
                name: "ATOne",
                auth: true,
                exact: true,
            },
            {
                component: "ATTwo",
                path: "/activity2",
                componentPath: "pages/StoryPage/Activity/Two/ATTwo",
                name: "ATTwo",
                auth: true,
                exact: true,
            },
            {
                component: "ATThree",
                path: "/activity3",
                componentPath: "pages/StoryPage/Activity/Two/ATThree",
                name: "ATThree",
                auth: true,
                exact: true,
            },
            {
                component: "ATFour",
                path: "/activity4",
                componentPath: "pages/StoryPage/Activity/Two/ATFour",
                name: "ATFour",
                auth: true,
                exact: true,
            },
            {
                component: "ATFive",
                path: "/activity5",
                componentPath: "pages/StoryPage/Activity/Two/ATFive",
                name: "ATFive",
                auth: true,
                exact: true,
            },
            {
                component: "ATSix",
                path: "/activity6",
                componentPath: "pages/StoryPage/Activity/Two/ATSix",
                name: "ATSix",
                auth: true,
                exact: true,
            },
            {
                component: "ATSeven",
                path: "/activity7",
                componentPath: "pages/StoryPage/Activity/Two/ATSeven",
                name: "ATSeven",
                auth: true,
                exact: true,
            },
            {
                component: "ATEight",
                path: "/activity8",
                componentPath: "pages/StoryPage/Activity/Two/ATEight",
                name: "ATEight",
                auth: true,
                exact: true,
            },
            {
                component: "ATNine",
                path: "/activity9",
                componentPath: "pages/StoryPage/Activity/Two/ATNine",
                name: "ATNine",
                auth: true,
                exact: true,
            },
            {
                component: "ATTen",
                path: "/activity10",
                componentPath: "pages/StoryPage/Activity/Two/ATTen",
                name: "ATTen",
                auth: true,
                exact: true,
            },
            {
                component: "ATEleven",
                path: "/activity11",
                componentPath: "pages/StoryPage/Activity/Two/ATEleven",
                name: "ATEleven",
                auth: true,
                exact: true,
            },
            {
                component: "ATTwelve",
                path: "/activity12",
                componentPath: "pages/StoryPage/Activity/Two/ATTwelve",
                name: "ATTwelve",
                auth: true,
                exact: true,
            },
            {
                component: "ATThirteen",
                path: "/activity13",
                componentPath: "pages/StoryPage/Activity/Two/ATThirteen",
                name: "ATThirteen",
                auth: true,
                exact: true,
            },
            {
                component: "ATFourteen",
                path: "/activity14",
                componentPath: "pages/StoryPage/Activity/Two/ATFourteen",
                name: "ATFourteen",
                auth: true,
                exact: true,
            },
            {
                component: "ATFifteen",
                path: "/activity15",
                componentPath: "pages/StoryPage/Activity/Two/ATFifteen",
                name: "ATFifteen",
                auth: true,
                exact: true,
            },
        ],
    },
    {
        component: "MeditationLayout",
        path: "/meditation",
        auth: true,
        name: "MeditationLayout",
        exact: false,
        redirect: "/meditation/one",
        childrens: [
            {
                component: "MeditationHomePages",
                path: "/homePage",
                componentPath: "pages/MeditationHomePages/MeditationHomePages",
                name: "MeditationHomePages",
                auth: true,
                exact: true,
            },
            {
                component: "RatAppearancePage",
                path: "/one",
                componentPath: "pages/MeditationPage/RatAppearancePage",
                name: "RatAppearancePage",
                auth: true,
                exact: true,
            },
            {
                component: "MonkeyAppearancePage",
                path: "/two",
                componentPath: "pages/MeditationPage/MonkeyAppearancePage",
                name: "MonkeyAppearancePage",
                auth: true,
                exact: true,
            },
            {
                component: "DuckAppearancePage",
                path: "/three",
                componentPath: "pages/MeditationPage/DuckAppearancePage",
                name: "DuckAppearancePage",
                auth: true,
                exact: true,
            },
        ],
    },

    {
        component: "IntroActivityTest",
        path: "/IntroActivityTest",
        auth: true,
        name: "IntroActivityTest",
        exact: false,
        childrens: [
            {
                component: "IntroActivityTestPage",
                path: "/",
                componentPath: "pages/IntroActivityPage/IntroActivityTest",
                name: "IntroActivityTestPage",
                auth: true,
                exact: true,
            },
        ],
    },

    {
        component: "IntroActvityOne",
        path: "/IntroActivity1",
        auth: true,
        name: "IntroActvityOne",
        exact: false,
        childrens: [
            {
                component: "IntroActivityPage",
                path: "/",
                componentPath: "pages/IntroActivityPage/IntroActvityOne",
                name: "IntroActvityOne",
                auth: true,
                exact: true,
            },
        ],
    },

    {
        component: "IntroActivityTwo",
        path: "/IntroActivity2",
        auth: true,
        name: "IntroActivityTwo",
        exact: false,
        childrens: [
            {
                component: "IntroActivityPage",
                path: "/",
                componentPath: "pages/IntroActivityPage/IntroActivityTwo",
                name: "IntroActvityOne",
                auth: true,
                exact: true,
            },
        ],
    },

    {
        component: "IntroActivityThree",
        path: "/IntroActivity3",
        auth: true,
        name: "IntroActivityThree",
        exact: false,
        childrens: [
            {
                component: "IntroActivityPage",
                path: "/",
                componentPath: "pages/IntroActivityPage/IntroActivityThree",
                name: "IntroActivityThree",
                auth: true,
                exact: true,
            },
        ],
    },

    {
        component: "IntroActivityFour",
        path: "/IntroActivity4",
        auth: true,
        name: "IntroActivityFour",
        exact: false,
        childrens: [
            {
                component: "IntroActivityPage",
                path: "/",
                componentPath: "pages/IntroActivityPage/IntroActivityFour",
                name: "IntroActivityFour",
                auth: true,
                exact: true,
            },
        ],
    },

    {
        component: "IntroActivityFive",
        path: "/IntroActivity5",
        auth: true,
        name: "IntroActivityFive",
        exact: false,
        childrens: [
            {
                component: "IntroActivityPage",
                path: "/",
                componentPath: "pages/IntroActivityPage/IntroActivityFive",
                name: "IntroActivityFive",
                auth: true,
                exact: true,
            },
        ],
    },

    {
        component: "StoryLayout",
        path: "/story-one",
        auth: true,
        name: "StoryLayout",
        exact: false,
        // redirect: "/activity-section/activity1",
        childrens: [
            {
                component: "StoryonePage",
                path: "/",
                componentPath: "pages/StoryPage/StoryonePage",
                name: "StoryonePage",
                auth: true,
                exact: true,
            },
            {
                component: "ActivityOnePage",
                path: "/activity1",
                componentPath: "pages/ActivityPage/ActivityOnePage",
                name: "ActivityOnePage",
                auth: true,
                exact: true,
            },
            {
                component: "ActivityTwoPage",
                path: "/activity2",
                componentPath: "pages/ActivityPage/ActivityTwoPage",
                name: "ActivityTwoPage",
                auth: true,
                exact: true,
            },
            {
                component: "ActivityThreePage",
                path: "/activity3",
                componentPath: "pages/ActivityPage/ActivityThreePage",
                name: "ActivityThreePage",
                auth: true,
                exact: true,
            },
            {
                component: "ActivityFourPage",
                path: "/activity4",
                componentPath: "pages/ActivityPage/ActivityFourPage",
                name: "ActivityFourPage",
                auth: true,
                exact: true,
            },
            {
                component: "ActivityFivePage",
                path: "/activity5",
                componentPath: "pages/ActivityPage/ActivityFivePage",
                name: "ActivityFivePage",
                auth: true,
                exact: true,
            },
            {
                component: "ActivitySixPage",
                path: "/activity6",
                componentPath: "pages/ActivityPage/ActivitySixPage",
                name: "ActivitySixPage",
                auth: true,
                exact: true,
            },
            {
                component: "ActivitySevenPage",
                path: "/activity7",
                componentPath: "pages/ActivityPage/ActivitySevenPage",
                name: "ActivitySevenPage",
                auth: true,
                exact: true,
            },
            {
                component: "ActivityEightPage",
                path: "/activity8",
                componentPath: "pages/ActivityPage/ActivityEightPage",
                name: "ActivityEightPage",
                auth: true,
                exact: true,
            },
            {
                component: "ActivityNinePage",
                path: "/activity9",
                componentPath: "pages/ActivityPage/ActivityNinePage",
                name: "ActivityNinePage",
                auth: true,
                exact: true,
            },
            {
                component: "ActivityTenPage",
                path: "/activity10",
                componentPath: "pages/ActivityPage/ActivityTenPage",
                name: "ActivityTenPage",
                auth: true,
                exact: true,
            },
            {
                component: "ActivityElevenPage",
                path: "/activity11",
                componentPath: "pages/ActivityPage/ActivityElevenPage",
                name: "ActivityElevenPage",
                auth: true,
                exact: true,
            },
          
        ],
    },

    {
        component: "HomepgLayout",
        path: "/home",
        auth: true,
        name: "HomepgLayout",
        exact: false,
        childrens: [
            {
                component: "HomePage",
                path: "/",
                componentPath: "pages/HomePage/HomePage",
                name: "HomePage",
                auth: true,
                exact: true,
            },
        ],
    },
    {
        component: "HomepgLayout",
        path: "/closure",
        auth: true,
        name: "HomepgLayout",
        exact: false,
        childrens: [
            {
                component: "ClosureOne",
                path: "/",
                componentPath: "pages/Closuresection/ClosureOne",
                name: "ClosureOne",
                auth: true,
                exact: true,
            },
        ],
    },
    {
        component: "GamesLayout",
        path: "/games",
        auth: false,
        name: "GamesLayout",
        exact: false,
        redirect: "/games",
        childrens: [
            {
                component: "GameSection",
                path: "/gameSection",
                componentPath: "pages/GameSection/GameSection",
                name: "GameSection",
                auth: false,
                exact: true,
            },
            {
                component: "MazeGame",
                path: "/mazeGame",
                componentPath: "pages/MazeGame/MazeGame",
                name: "MazeGame",
                auth: false,
                exact: true,
            },
            {
                component: "MemoryGame",
                path: "/memoryGame",
                componentPath: "pages/MemoryGame/MemoryGame",
                name: "MemoryGame",
                auth: false,
                exact: true,
            },
            {
                component: "ColorGame",
                path: "/colorGame",
                componentPath: "pages/ColorGame/ColorGame",
                name: "ColorGame",
                auth: false,
                exact: true,
            },
        ],
    },
];

export default routers;
