import React, { useState, useEffect } from "react"
import "./style.css"


export const CheckBox = ({ top, Ml, left, classes }) => {

    const [isChecked, setIsChecked] = useState(false);
    const [count, setCount] = useState(0)

    const handleOnChange = () => {
        setIsChecked(!isChecked);



    }


    return (
        <>
            <div>
                <input
                    style={{ position: "fixed !important", top: top, marginLeft: Ml, left: left }}
                    onChange={handleOnChange}
                    className={`${classes}`}
                    type="checkbox"
                    value=""

                    checked={isChecked} />
            </div>

        </>
    )




}

