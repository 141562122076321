export const activityData = {
  rangeSlider: "RANGE_SLIDER",
};

export const activityTwo = {
  formOne: "ACTIVITY_TWO_FORM_ONE",
  formTwo: "ACTIVITY_TWO_FORM_TWO",
  formThree: "ACTIVITY_TWO_FORM_Three",
  formFive: "ACTIVITY_TWO_FORM_FIVE",
  formSix: "ACTIVITY_TWO_FORM_Six",
  formSeven: "ACTIVITY_TWO_FORM_SEVEN",
  formTen: "ACTIVITY_TWO_FORM_TEN",
  formEleven: "ACTIVITY_TWO_FORM_ELEVEN",
  formThirteen: "ACTIVITY_TWO_FORM_THIRTEEN",
  formFourteen: "ACTIVITY_TWO_FORM_FOURTEEN",
  formFifteen: "ACTIVITY_TWO_FORM_FIFTEEN",
};

export const activityOne = {
  formOne: "ACTIVITY_ONE_FORM_ONE",
  formThree: "ACTIVITY_ONE_FORM_THREE",
  formFive: "ACTIVITY_ONE_FORM_FIVE",
  formSix: "ACTIVITY_ONE_FORM_SIX",
  formSeven: "ACTIVITY_ONE_FORM_SEVEN",
  formNine: "ACTIVITY_ONE_FORM_NINE",
  formEleven: "ACTIVITY_ONE_FORM_ELEVEN",
};

export const activityThree = {
  formOne: "ACTIVITY_THREE_FORM_ONE",
  formTwo: "ACTIVITY_THREE_FORM_TWO",
  formThree: "ACTIVITY_THREE_FORM_THREE",
  formFour: "ACTIVITY_THREE_FORM_FOUR",
  formFive: "ACTIVITY_THREE_FORM_FIVE",
  formSix: "ACTIVITY_THREE_FORM_SIX"
};


export const IntroActivityOne = {
  formOne: "INTRO_ACTIVITY_ONE_FORM_ONE",
};

export const closureOne = {
  closureFormOne: "CLOSURE_FORM_ONE",
  closureFormTwo: "CLOSURE_FORM_TWO",
  closureFormThree: "CLOSURE_FORM_THREE",
  closureFormFour: "CLOSURE_FORM_FOUR",
  closureFormFive: "CLOSURE_FORM_FIVE",
  closureFormSix: "CLOSURE_FORM_SIX",
  closureFormSeven: "CLOSURE_FORM_SEVEN",
  closureFormEight: "CLOSURE_FORM_EIGHT",
  closureFormNine: "CLOSURE_FORM_NINE",
  closureFormEleven: "CLOSURE_FORM_ELEVEN",
};

export const storyFlow = {
  activeSlideOne: "ACTIVESLIDE_ONE",
  activeSlideTwo: "ACTIVESLIDE_TWO",
  activeSlideThree: "ACTIVESLIDE_THREE",
  activityThree: "ACTIVITY_THREE",
  activitySix: "ACTIVITY_SIX",
  hideButtonOne: "HIDEBUTTON_ONE",
  storyOneProgress: "STORYONE_PROGRESS",
  storyTwoProgress: "STORYTWO_PROGRESS",
  storyThreeProgress: "STORYTHREE_PROGRESS",
};

export const popup = {
  popupOne: "POPUP_ONE",
  popupTwo: "POPUP_TWO",
};
