// import {IntroActivityOne} from "../component/IntroActivity/IntroActivityOne"

export { AuthLayout } from "./AuthLayout";
export { AdminLayout } from "./AdminLayout";
export { LoginLayout } from "./LoginLayout";
export { StoryLayout } from "./StoryLayout";
export { HomepgLayout } from "./HomepgLayout";
export { GamesLayout } from './GamesLayout';
export { MeditationLayout } from "./MeditationLayout"
export { ActivityLayout } from "./ActivityLayout"
export { Dashboard } from "../component/Dashboard/Dashboard";
export { IntroActvityOne } from "../component/IntroActivity/IntroActivityOne"
export { IntroActivityTwo } from "../component/IntroActivity/IntroActivityTwo"
export { IntroActivityThree } from "../component/IntroActivity/IntroActivityThree"
export { IntroActivityFour } from "../component/IntroActivity/IntroActivityFour"
export { IntroActivityFive } from "../component/IntroActivity/IntroActivityFive"
export { IntroActivityTest } from "../component/IntroActivity/IntroActivityTest"



