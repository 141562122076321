import React, { useState, useEffect } from "react";
import IntroActvityNav from "../common/IntroActivityNav/IntroActivitYNav";
import "./Style/IntroActivityOne.scss";
import TextIcon from "./Images/5.png";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IntroActivityOneFormOne } from "action/IntroActivity";
import { Closurebutton } from "component/common/Closurebutton";
import { HomePages } from "component/HomePages";

export const IntroActvityOne = () => {
  const dispatch = useDispatch();
  // const [toast, setToast] = useState(false)

  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [symbolsArr] = useState(["e", "E", "+", "-", "."]);
  const [number, setNumber] = useState('')
  const [IntroActformData, setIntroActFormData] = useState({
    fieldOne: "",
    fieldTwo: "",
    fieldThree: "",
    fieldFour: "",
    fieldFive: "",
    fieldSix: "",
  });
  
  const handleChangeValue = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      setNumber(e.target.value)
    }
    const { name, value } = e.target || e || {};
    setIntroActFormData({ ...IntroActformData, [name]: value });
  };
 

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      history.push("/IntroActivity2");
    }
  }, [formErrors]);

  const validate = (values) => {
    const errors = {};
    if (!values.fieldOne) {
      errors.fieldOne = "Por favor, escriba su nombre";
    }
    if (!values.fieldTwo) {
      errors.fieldTwo = "Por favor, escribe aquí tu color favorito";
    }
    if (!values.fieldThree) {
      errors.fieldThree = "Por favor, escribe aquí tu edad";
    }
    if (!values.fieldFour) {
      errors.fieldFour = "Por favor, escribe aquí tu comida favorita";
    }
    if (!values.fieldFive) {
      errors.fieldFive = "Por favor, escribe aquí lo que más te gusta hacer";
    }
    if (!values.fieldSix) {
      errors.fieldSix = " Por favor, escribe aquí a qué alimentos tienes alergias";
    }
    return errors;
  };

  const handleSubmit = () => {
    setFormErrors(validate(IntroActformData));
    dispatch(IntroActivityOneFormOne(IntroActformData));
    setIsSubmit(true);
  };

  const history = useHistory();
  const prefieldData = useSelector(
    (state) => state.IntroActvityOne.formOne
  );
  return (
    <>
      <div className="IntroBackground">
        <IntroActvityNav
          InProgressCar="16%"
          InProgress="20%"
          TextIcon={TextIcon}
        ></IntroActvityNav>
        <div className="row text-center mt-1 pb-4">
          <div className="col-md-12">
            <h1 className="IntroAct-heading">HACEMOS NUEVOS AMIGOS</h1>
          </div>
        </div>

        {/*-------------------------form-section---------------------------------------------*/}

        {/* -------------------------form-one----------------------------- */}
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-5 formColRight">
            <form>
              <div className="form-group">
                <p className="LabelText">¿Cómo te llamas?</p>
                <textarea
                  defaultValue={prefieldData != null ? prefieldData.fieldOne : ''}
                  name="fieldOne"
                  onChange={handleChangeValue}
                  className="form-control form-control-IntroAct1"
                  id="exampleFormControlTextarea1"
                  rows="5"
                >
                  {/* {fieldOne} */}
                </textarea>
              </div>
              <p className="error-text">{formErrors.fieldOne}</p>
            </form>
          </div>
          <div className="col-md-5 formColLeft">
            <form>
              <div className="form-group">
                <p className="LabelText">¿Cuál es tu color favorito?</p>
                <textarea
                  defaultValue={prefieldData != null ? prefieldData.fieldTwo : ''}
                  name="fieldTwo"
                  onChange={handleChangeValue}
                  className="form-control form-control-IntroAct1"
                  id="exampleFormControlTextarea1"
                  rows="5"
                >
                  {/* {fieldTwo} */}
                </textarea>
              </div>
              <p className="error-text">{formErrors.fieldTwo}</p>
            </form>
          </div>

          <div className="col-md-1"></div>
        </div>

        {/* ------------------form-two---------- */}
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-5 formColRight">
            <form>
              <div className="form-group">
                <p className="LabelText">¿Cuantos años tienes?</p>
                <textarea
                  defaultValue={prefieldData != null ? prefieldData.fieldThree : ''}
                  maxLength='2'
                  onInput={(e) => {
                    let invalidChars = /[^0-9]/gi
                    if (invalidChars.test(e.target.value)) {
                      e.target.value = e.target.value.replace(invalidChars, '')
                    }
                  }}

                  name="fieldThree"
                  onKeyDown={(e) => {
                    symbolsArr.includes(e.key) && e.preventDefault()

                  }

                  }
                  onChange={handleChangeValue}
                  className="form-control form-control-IntroAct1"
                  id="formGroupExampleInput"
                  style={{ height: "140px" }}
                ></textarea>
              </div>
              <p className="error-text">{formErrors.fieldThree}</p>
            </form>
          </div>
          <div className="col-md-5 formColLeft">
            <form>
              <div className="form-group">
                <p className="LabelText">¿Cuál es tu comida preferida?</p>
                <textarea
                  defaultValue={prefieldData != null ? prefieldData.fieldFour : ''}
                  name="fieldFour"
                  onChange={handleChangeValue}
                  className="form-control form-control-IntroAct1"
                  id="exampleFormControlTextarea1"
                  rows="5"
                >
                  {/* {fieldFour} */}
                </textarea>
              </div>
              <p className="error-text">{formErrors.fieldFour}</p>
            </form>
          </div>

          <div className="col-md-1"></div>
        </div>

        {/* --------------------------form-three------------------------------------ */}

        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-5 formColRight">
            <form>
              <div className="form-group">
                <p className="LabelText">¿Qué es lo que más te gusta hacer?</p>
                <textarea
                  defaultValue={prefieldData != null ? prefieldData.fieldFive : ''}
                  name="fieldFive"
                  onChange={handleChangeValue}
                  className="form-control form-control-IntroAct1"
                  id="exampleFormControlTextarea1"
                  rows="5"
                >
                  {/* {fieldFive} */}
                </textarea>
              </div>
              <p className="error-text">{formErrors.fieldFive}</p>
            </form>
          </div>
          <div className="col-md-5 formColLeft">
            <form>
              <div className="form-group">
                <p className="LabelText">¿A qué alimento/s tienes alergia?</p>
                <textarea
                  defaultValue={prefieldData != null ? prefieldData.fieldSix : ''}
                  name="fieldSix"
                  onChange={handleChangeValue}
                  className="form-control form-control-IntroAct1"
                  id="exampleFormControlTextarea1"
                  rows="5"
                >
                  {/* {fieldSix} */}
                </textarea>
              </div>
              <p className="error-text">{formErrors.fieldSix}</p>
            </form>
          </div>

          <div className="col-md-1"></div>
        </div>

        {/* <div className="row pb-4">
                    <div className="col-md-2"></div>
                    <div className="col-md-8"></div>
                    <div className="col-md-2 mt-4 FooterFlexButton">
                        <div className='btn-outer-border'>
                            <div className="btn-bg">
                                <img className='first-line' src={firstDot}></img>
                                <img className='second-line' src={secondDot}></img>
                                <button type="button" className="footerButton btn btn-info" onClick={handleSubmit}>HECHO</button>
                            </div>
                        </div>
                    </div>
                </div> */}

        <div className="row w-100">
          <div className="col py-4">
            <div className="d-flex justify-content-end pr-5">
              <Closurebutton onClick={handleSubmit}>
                <span className="introact-one-hecho-validator">hecho</span>
              </Closurebutton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
