import React, { Component } from "react";
import Logout from '../assets/images/logout.png';

export class HomepgLayout extends Component {
  render() {
    let { children } = this.props;

    return (
      <div className="container-fluid home_LayouT">
          {children}
      </div>
    );
  }
}

