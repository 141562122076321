/* eslint-disable react/react-in-jsx-scope */
import React from "react";
import BootstrapButton from "react-bootstrap/Button";
import firstDot from "../../../assets/images/first-dot.png";
import secondDot from "../../../assets/images/second-dot.png";
import "./Closurebutton.scss";

/**
 * Button: The Common Re-usable Button across website.
 * @return {JSX.Element} The JSX Code for Button
 */

export function Closurebutton({ children, onClick, onKeyPress }) {
  return (
    <div className="closure-border">
      <div className="the-closure">
        <BootstrapButton
          id="closurebtns"
          className="mx-auto d-block"
          onKeyPress={onKeyPress}
          onClick={onClick}
        >
          <div className="double-shadows">
            <div className="notify-white-shadow">
              <img className="img-fluid" src={firstDot} alt="shadow" />
              <img
                className="img-fluid shadowTwo"
                src={secondDot}
                alt="shadow"
              />
            </div>
          </div>
          <span className="closure-btn-Text fs-24 fw-400">{children}</span>
        </BootstrapButton>
      </div>
    </div>
  );
}
