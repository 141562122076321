import React, { useState } from "react";
import Draggable from "react-draggable";
import "./Style/IntroActivityTwo.scss";
import {
  introTwoItems,
  introTwoItemsDestination,
} from "../common/IntroActivityTwoArray/index";
import IntroActvityNav from "../common/IntroActivityNav/IntroActivitYNav";
import { Closurebutton } from "component/common/Closurebutton/index";
import HeadingText from "./Images/INTRODUCCIÓN_EMOCIONES.png";
import Text from "./Images/Text.png";
import firstDot from "../../assets/images/first-dot.png";
import secondDot from "../../assets/images/second-dot.png";
import TextIcon from "./Images/TextIcon2.png";
import smallStar from "./Images/smallstar.png";
import outlineStar from "./Images/outlineStar.png";
import starWrong from "assets/images/star-wrong.png";
import Lock from "assets/images/lock.png";
import { history } from "service/helpers";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export function IntroActivityTwo() {
  const [activeFace, setActiveFace] = useState(null);
  const [leftEmotion, setLeftEmotion] = useState(null);
  const [rightEmotion, setRightEmotion] = useState(null);
  const [corretFaces, setCorrectFaces] = useState(introTwoItemsDestination);
  const [activeDrags, setActiveDrags] = useState(0);
  const [deltaPosition, setDeltaPosition] = useState({
    x: 0,
    y: 0,
  });
  toast.configure()
  const notify = () => {

    // Calling toast method by passing string
    toast('por favor haga coincidir las caras correctas')
  }

  let count = 0;
  if (corretFaces[0].matched === true) {
    count++
  } if (corretFaces[1].matched === true) {
    count++
  } if (corretFaces[2].matched === true) {
    count++
  } if (corretFaces[3].matched === true) {
    count++
  } if (corretFaces[4].matched === true) {
    count++
  } if (corretFaces[5].matched === true) {
    count++
  }


  const [controlledPosition, setControlledPosition] = useState({
    x: 0,
    y: 0,
  });

  const handleDrag = (e, ui) => {
    const { x, y } = deltaPosition;
    setDeltaPosition({
      x: x + ui.deltaX,
      y: y + ui.deltaY,
    });
  };

  const onStart = () => {
    setActiveDrags(activeDrags + 1);
  };

  const onStop = () => {
    setActiveDrags(activeDrags - 1);
  };
  // For controlled component
  const adjustXPos = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { x, y } = controlledPosition;
    setControlledPosition({ controlledPosition: { x: x - 10, y } });
  };

  const adjustYPos = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { controlledPosition } = this.state;
    const { x, y } = controlledPosition;
    this.setState({ controlledPosition: { x, y: y - 10 } });
  };
  const onControlledDrag = (e, position) => {
    const { x, y } = position;
    this.setState({ controlledPosition: { x, y } });
  };

  const onControlledDragStop = (e, position) => {
    this.onControlledDrag(e, position);
    this.onStop();
  };

  const dragHandlers = { onStart: onStart, onStop: onStop };

  const handleLeftFace = (id, emotion, image) => {
    setRightEmotion(image);
    setActiveFace(id);
    setLeftEmotion(emotion);
  };
  const handleRightFace = (emotion) => {
    if (leftEmotion === emotion) {
      setCorrectFaces((prevItem) => {
        return prevItem.map((item) => {
          if (item.emotion === emotion) {
            return { ...item, matched: true, correctImage: rightEmotion };
            notify()
          } else {
            return item;
          }
        });
      });
    } else {
      if (leftEmotion) {
        setCorrectFaces((prevItem) => {
          return prevItem.map((item) => {
            if (item.emotion === emotion) {
              return { ...item, matched: false, correctImage: rightEmotion };
            } else {
              return item;
            }
          });
        });
      }
    }
  };
  const handleReset = () => {
    setLeftEmotion(null);
    setRightEmotion(null);
    setActiveFace(null);
    window.location.reload();
  };

  return (
    <div>
      <div className="IntroBackground">
        <IntroActvityNav
          InProgressCar="35%"
          InProgress="40%"
          TextIcon={TextIcon}
        ></IntroActvityNav>

        <div className="row text-center">
          <div className="col-md-3"></div>
          <div className="col-md-7 mt-3">
            {/* <img src={HeadingText}></img> */}
            <h1 className="IntroActtwo-heading">INTRODUCCIÓN:EMOCIONES</h1>
          </div>
          <div className="col-md-3"></div>
        </div>

        <div className="row text-center mt-4">
          <div className="col-md-3"></div>
          <div className="col-md-7">
            {/* <img className="TextImg" src={Text} ></img> */}
            <p className="Intro-act-para">
              Dibuja la expresión de las diferentes emociones en estas caras.
            </p>
          </div>
          <div className="col-md-3"></div>
        </div>

        {/* -----------Parent-Row----------------- */}

        <div className="row mt-1 centered-card">
          <div className="col-md-2"></div>
          <div className="grid-six Card">
            {introTwoItems.map((faces, idx) => {
              return (
                <div
                  className={
                    corretFaces?.find((val) => val === faces.emotion)
                      ? "disappear"
                      : "face-cont"
                  }
                  id={idx}
                  key={idx}
                >
                  <img
                    onClick={() => {
                      handleLeftFace(idx, faces?.emotion, faces?.image);
                    }}
                    className={
                      activeFace === idx ? "face-image-selected" : "face-image"
                    }
                    src={faces.dest}
                  />
                </div>
              );
            })}
          </div>
          <div className="col-md-5 ml-5">
            <div className="grid-three-alt">
              {corretFaces.map((faces, idx) => {
                return (
                  <div className="desstination-face-cont" id={idx} key={idx}>
                    <ToastContainer
                      position="top-center"
                    />
                    <img
                      src={
                        faces.matched === null
                          ? outlineStar
                          : faces.matched === true
                            ? smallStar
                            : starWrong
                      }
                      className="small-star"
                    />
                    <img
                      onClick={() => {
                        handleRightFace(faces?.emotion);
                      }}
                      className="face-image-destination"
                      src={
                        faces.matched === null
                          ? faces.image
                          : faces.matched === true
                            ? faces.correctImage
                            : faces.correctImage
                      }
                    />

                    <p className="emotion-names">{faces.emotion}</p>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="col-md-1"></div>
        </div>
        <div className="row pb-5">
          <div className="col-md-8"></div>
          <div className="col-md-2 FooterFlexButton">
            <Closurebutton onClick={handleReset}><span className="px-3">REINICIAR</span></Closurebutton>
          </div>
          <div className="col-md-2 px-5">
            <div className="col-md-2 FooterFlexButton">
              <div className='btn-outer-border'>
                <div className="btn-bg">
                  <img className='first-line' src={firstDot}></img>
                  <img className='second-line' src={secondDot}></img>
                  <button type="button" className="footerButton btn btn-info" onClick={() => { if (count === 6) { history.push('/IntroActivity3') } else { notify() } }}>HECHO</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
