import { popup } from "service/actionType";

const initialState = {
  popupOne: {},
  popupTwo: {},
};

const PopupReducer = (state = initialState, action) => {
  switch (action.type) {
    case popup.popupOne:
      return {
        ...state,
        popupOne: action.payload,
      };
    case popup.popupTwo:
      return {
        ...state,
        popupTwo: action.payload,
      };
    default:
      return state;
  }
};

export default PopupReducer;
