import React, { useRef } from "react";
import "./Style/IntroActivityThree.scss";
import IntroActvityNav from "../common/IntroActivityNav/IntroActivitYNav";
import { FiEdit2 } from "react-icons/fi";
import HeadingText from "./Images/FELICIDAD.png";
import firstDot from "../../assets/images/first-dot.png";
import secondDot from "../../assets/images/second-dot.png";
import DrawingBox from "../common/DrawingBox/DrawingBox";
import TextIcon3 from "./Images/TextIcon3.png";
import ChatBoxIcon from "./Images/ChatBoxIcon.png";
import EditIcon from "assets/images/EditIcon.png";
import { history } from "service/helpers";
import { Closurebutton } from "component/common/Closurebutton";

export const IntroActivityThree = () => {
  const firstCanvas = useRef();

  const clear = () => {
    firstCanvas.current.clear();
  };

  return (
    <>
      <div className="IntroBackground">
        <IntroActvityNav
          InProgressCar="55%"
          InProgress="60%"
          TextIcon={TextIcon3}
        ></IntroActvityNav>

        <div className="row text-center mt-5 pb-2">
          <div className="col-md-3"></div>
          <div className="col-md-7">
            <h1 className="Intro-three-headingText">FELICIDAD</h1>
          </div>
          <div className="col-md-2"></div>
        </div>

        <div className="row text-center mt-0">
          <div className="col-md-3"></div>
          <div className="col-md-7 text-center">
            <p className="IntroActThreeText">
              Dibuja un momento de la semana en que te hayas sentido muy
              contento.{" "}
            </p>
          </div>

          <div className="col-md-2"></div>
        </div>

        <div className="row mt-0">
          <div className="col-md-4"></div>
          <div className="col-md-6">
            <img
              src={EditIcon}
              alt="edit-icon"
              onClick={clear}
              className="edit-icon"
            />
            {/* <button onClick={clear} className="btn btn-primary clear-btn">Clear</button> */}
          </div>
          <div className="col-md-2"></div>
        </div>

        <div className="row text-center mt-4">
          <div className="col-md-3"></div>

          <div className="col-md-7 canvas-col text-center">
            <DrawingBox className="DrawingBox" firstCanvas={firstCanvas} />

            {/* <CanvasDraw
                        style={{border: '15px solid brown', borderRadius: '20px' }}
                        brushRadius={1}
                        canvasWidth= {800}
                        canvasHeight={400}
                        hideGrid={true}
                        ref={firstCanvas}
                       
                        
                        >

                        </CanvasDraw> */}

            {/* <div className="FlexBox">
                            <button className="btn btn-primary EditButton"><FiEdit2 color="brown"></FiEdit2></button>
                        </div> */}
          </div>
          <div className="col-md-2"></div>
        </div>

        {/* <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-8"></div>

                    <div className="col-md-2 mt-4 FooterFlexButton pb-2">
                        <div className='btn-outer-border'>
                            <div className="btn-bg">
                                <img className='first-line' src={firstDot}></img>
                                <img className='second-line' src={secondDot}></img>
                                <button type="button" className="footerButton btn btn-info" onClick={() => history.push('/IntroActivity4')} >HECHO</button>
                            </div>
                        </div>
                    </div>

                </div> */}
        <div className="row w-100">
          <div className="col py-4">
            <div className="d-flex justify-content-end pr-5">
              <div className="introthree-topward">
              <Closurebutton onClick={() => history.push("/IntroActivity4")}>
                <span className="introact-three-hecho-valiadtor px-4">hecho</span>
              </Closurebutton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
