import React from 'react';

export const Dashboard = () =>{
    return(
        <div className='row'>
            <div className='col bg-info p-5'>
                <p>hello world</p>
            </div>
        </div>
    )
}