import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./style.scss";
import { Progressbar } from "../ProgressBar/ProgressBar";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { AiOutlineArrowRight } from "react-icons/ai";
import { AiFillHome } from "react-icons/ai";
import LfArrow from "../../IntroActivity/Images/Leftarrow.png";
import RfArrow from "../../IntroActivity/Images/RightArrow.png";
import homeIcon from "../../IntroActivity/Images/homeIntro.png";
import ScrollingCar from "../../IntroActivity/Images/image 30 (1).png";
import StarIcon from "../../IntroActivity/Images/starIcon.png";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { storyTwoActivityData } from "utils/commonMock";
import { storyOneActivityData } from "utils/commonMock";
import { popup } from "service/actionType";

const ActvityTwoNav = ({
  splitKeyword,
  limit,
  urlNum,
  InProgressCar,
  activityTwo,
}) => {
 
  const history = useHistory();
  const [urlParamNum, setUrlParamNum] = useState(null);
  const [urlParam, setUrlParam] = useState(null);
  const [activityone, setActivityOne] = useState("");
  const [activitytwo, setActivityTwo] = useState("");
  const id = useParams();

  let IntroActivityNumber, DecreamentIntroActivityNumber;
  let url = window.location.href;
  let path = url.split("/");
  let numberValue = path[urlNum];
  let Stringnumber = +numberValue.split(splitKeyword)[1];
  useEffect(() => {
    console.log("path", path[3]);
    setUrlParamNum(Stringnumber - 1);
    setUrlParam(true);
    console.log("url", urlParam);
    // console.log("Stringnumber", Stringnumber)
    console.log("numberValue", numberValue);
    if (limit === 11) {
      if (Stringnumber === 1) {
        setActivityOne("60%");
      }
      if (Stringnumber === 2) {
        setActivityOne("64%");
      }
      if (Stringnumber === 3) {
        setActivityOne("68%");
      }
      if (Stringnumber === 4) {
        setActivityOne("72%");
      }
      if (Stringnumber === 5) {
        setActivityOne("76%");
      }
      if (Stringnumber === 6) {
        setActivityOne("80%");
      }
      if (Stringnumber === 7) {
        setActivityOne("84%");
      }
      if (Stringnumber === 8) {
        setActivityOne("88%");
      }
      if (Stringnumber === 9) {
        setActivityOne("92%");
      }
      if (Stringnumber === 10) {
        setActivityOne("96%");
      }
      if (Stringnumber === 11) {
        setActivityOne("100%");
      }
      localStorage.setItem("storyone-count", activityone);
    }
    if (limit === 15) {
      if (Stringnumber === 1) {
        setActivityTwo("54%");
      }
      if (Stringnumber === 2) {
        setActivityTwo("57%");
      }
      if (Stringnumber === 3) {
        setActivityTwo("60%");
      }
      if (Stringnumber === 4) {
        setActivityTwo("63%");
      }
      if (Stringnumber === 5) {
        setActivityTwo("66%");
      }
      if (Stringnumber === 6) {
        setActivityTwo("69%");
      }
      if (Stringnumber === 7) {
        setActivityTwo("72%");
      }
      if (Stringnumber === 8) {
        setActivityTwo("75%");
      }
      if (Stringnumber === 9) {
        setActivityTwo("78%");
      }
      if (Stringnumber === 10) {
        setActivityTwo("81%");
      }
      if (Stringnumber === 11) {
        setActivityTwo("84%");
      }
      if (Stringnumber === 12) {
        setActivityTwo("87%");
      }
      if (Stringnumber === 13) {
        setActivityTwo("90%");
      }
      if (Stringnumber === 14) {
        setActivityTwo("93%");
      }
      if (Stringnumber === 15) {
        setActivityTwo("100%");
      }
      localStorage.setItem("storytwo-count", activitytwo);
    }
    IntroActivityNumber =
      Stringnumber < limit ? Stringnumber + 1 : Stringnumber;
    DecreamentIntroActivityNumber =
      Stringnumber > 1 ? Stringnumber - 1 : Stringnumber;
  });

  console.log("urlParamNum", storyTwoActivityData?.urlParamNum);
  let navigation = localStorage.setItem("navigation", "Activity-navigation");

  const popupOne = useSelector((state) => state.PopupReducer.popupOne);

  const popupTwo = useSelector((state) => state.PopupReducer.popupTwo);
  console.log(popupTwo)

  return (
    <>
      <div
        className={`${
          !popupOne || !popupTwo ? "blur-background" : ""
        } Activity-navigation`}
      >
        <div className="row text-center pt-4">
          <div className="col-md-3">
           
              {/* <div className="IntroBoxParent">
                <button
                  type="button"
                  className="Leftbox btn btn-info"
                  
                >
                  <img src={LfArrow} alt="leftArrow"></img>
                </button>
              </div> */}
              <>
                {popupOne && popupTwo ? (
                  <div className="IntroBoxParent">
                    <button
                      onClick={() => {
                        if(limit===11){
                          Stringnumber === 1 ? history.push("/story-one") : 
                          history.push(
                            "./activity" + DecreamentIntroActivityNumber
                          )
                        } if(limit === 15){
                          Stringnumber === 1 ? history.push("/story-two") : 
                          history.push(
                            "./activity" + DecreamentIntroActivityNumber
                          );
                        }
                        }}
                      type="button"
                      className="Leftbox btn btn-info"
                    >
                      <img src={LfArrow} alt="leftArrow"></img>
                    </button>
                  </div>
                ) : (
                  <div className="IntroBoxParent">
                    <button type="button" className="Leftbox btn btn-info">
                      <img src={LfArrow} alt="leftArrow"></img>
                    </button>
                  </div>
                )}

                <div className="IntroBoxParent">
                  <button
                    onClick={() => {
                      history.push("./activity" + IntroActivityNumber);
                    }}
                    type="button"
                    class="Leftbox btn btn-info"
                  >
                    <img src={RfArrow} alt="rightArrow"></img>
                  </button>
                </div>
              </>
            
          </div>

          {urlParam && (
            <div
              className= "col-md-7 mt-3"
            >
              {limit == 15 ? (
                <>
                  <Progressbar
                    inProgress={
                      urlParam
                        ? storyTwoActivityData[urlParamNum]?.InProgress
                        : "100%"
                    }
                    activityTwo
                  />
                  <img
                    style={{
                      left: urlParam
                        ? storyTwoActivityData[urlParamNum]?.InProgressCar
                        : "100%",
                    }}
                    className="ScrollingCar"
                    src={ScrollingCar}
                  />{" "}
                </>
              ) : (
                <>
                  {" "}
                  <Progressbar
                    inProgress={
                      urlParam
                        ? storyOneActivityData[urlParamNum]?.InProgress
                        : "100%"
                    }
                    activityTwo
                  />
                  <img
                    style={{
                      left: urlParam
                        ? storyOneActivityData[urlParamNum]?.InProgressCar
                        : "100%",
                    }}
                    className="ScrollingCar"
                    src={ScrollingCar}
                  />
                </>
              )}

              <div>
                <div>
                  <img className="Progress-StarIcon" src={StarIcon}></img>
                  {limit === 15 ? (
                    <>
                      <img
                        className="Progress-TextIcon"
                        src={storyTwoActivityData[urlParamNum]?.TextIcon}
                      ></img>
                    </>
                  ) : (
                    <div className="adjust-star-number-label">
                      {/* <p
                        className="Progress-TextIcon-S1"
                        style={
                          storyOneActivityData[urlParamNum]?.id > 9
                            ? { fontSize: "17px" }
                            : { fontSize: "18px" }
                        }
                      >
                        {storyOneActivityData[urlParamNum]?.S1TextIcon}{" "}
                      </p> */}
                      <p
                        className="Progress-TextIcon-S1"
                        style={
                          storyOneActivityData[urlParamNum]?.id > 9
                            ? { fontSize: "17px" }
                            : { fontSize: "18px" }
                        }
                      >
                        {storyOneActivityData[urlParamNum]?.S1TextIcon}{" "}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          <div className={Stringnumber == 1 ? "col-md-2" : "col-md-2"}>
            <div className="IntroBoxParent">
              <button
               disabled = {limit === 11? Stringnumber <11? true: false: limit === 15?Stringnumber<15?true:false:''}
                type="button"
                className="Rightbox btn btn-info"
                onClick={popupOne && popupTwo ? () => {
                  history.push("/home");
                } : null}
              >
                <img src={homeIcon}></img>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActvityTwoNav;
