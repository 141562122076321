export const introTwoItems = [
    {
        name: 'Face 1',
        image: require('../../IntroActivity/Images/Image1-alt.png'),
        dest: require('../../IntroActivity/Images/Image1.png'),
        emotion: "alegria"
    },
    {
        name: 'Face 2',
        image: require('../../IntroActivity/Images/Image2-alt.png'),
        dest: require('../../IntroActivity/Images/Image2.png'),
        emotion: "sorpresa"
    },
    {
        name: 'Face 3',
        image: require('../../IntroActivity/Images/Image3-alt.png'),
        dest: require('../../IntroActivity/Images/Image3.png'),
        emotion: "asco"

    },
    {
        name: 'Face 4',
        image: require('../../IntroActivity/Images/Image4-alt.png'),
        dest: require('../../IntroActivity/Images/Image4.png'),
        emotion: "tristeza"
    },
    {
        name: 'Face 5',
        image: require('../../IntroActivity/Images/Image5-alt.png'),
        dest: require('../../IntroActivity/Images/Image5.png'),
        emotion: "ira"
    },
    {
        name: 'Face 6',
        image: require('../../IntroActivity/Images/Image6-alt.png'),
        dest: require('../../IntroActivity/Images/Image6.png'),
        emotion: "miedo"
    },
]

export const introTwoItemsDestination = [
    {
        name: 'Face 1',
        correctImage: require('../../IntroActivity/Images/Image1-alt.png'),
        image: require('../../IntroActivity/Images/UnionImg.svg'),
        emotion: "sorpresa",
        matched: null,
    },
    {
        name: 'Face 2',
        correctImage: require('../../IntroActivity/Images/Image2-alt.png'),
        image: require('../../IntroActivity/Images/UnionImg.svg'),
        emotion: "asco",
        matched: null,
    },
    {
        name: 'Face 3',
        correctImage: require('../../IntroActivity/Images/Image3-alt.png'),
        image: require('../../IntroActivity/Images/UnionImg.svg'),
        emotion: "ira",
        matched: null,
    },
    {
        name: 'Face 4',
        correctImage: require('../../IntroActivity/Images/Image4-alt.png'),
        image: require('../../IntroActivity/Images/UnionImg.svg'),
        emotion: "alegria",
        matched: null,
    },
    {
        name: 'Face 5',
        correctImage: require('../../IntroActivity/Images/Image5-alt.png'),
        image: require('../../IntroActivity/Images/UnionImg.svg'),
        emotion: "tristeza",
        matched: null,
    },
    {
        name: 'Face 6',
        correctImage: require('../../IntroActivity/Images/Image6-alt.png'),
        image: require('../../IntroActivity/Images/UnionImg.svg'),
        emotion: "miedo",
        matched: null,
    },
]