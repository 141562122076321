/* eslint-disable react/react-in-jsx-scope */
import React from "react";
import BootstrapButton from "react-bootstrap/Button";
import "./Storyhomebtn.scss";

/**
 * Button: The Common Re-usable Button across website.
 * @return {JSX.Element} The JSX Code for Button
 */

export function Storyhomebtn({ children, onClick, disabledHomeButton }) {
  return (
    <BootstrapButton
      id="Storyhomebtns"
      onClick={onClick}
      className={disabledHomeButton}
    >
      {children}
    </BootstrapButton>
  );
}
