import { activityData } from "service/actionType";

const initialState = {
  rangeSlider: 25
};
const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case activityData.rangeSlider:
      return {
        ...state,
        rangeSlider: action.payload,
      };
    default:
      return state;
  }
};

export default commonReducer;
