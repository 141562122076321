import { axiosInstance } from "./utilities";

export var api = async function ({
    method = "get",
    api,
    body,
    options,
    status = false,
    token = "",
    baseURL = "normal",
}) {
    return await new Promise((resolve, reject) => {
        // setting token

        token = sessionStorage.getItem('token');
        if (token) {
            axiosInstance.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${token}`
        }
        axiosInstance.defaults.headers.common["user-type"] = 4;

        axiosInstance[method](`${getServiceUrl(baseURL)}${api}`, body ? body : "", options ? options : '')
            .then((data) => {
                resolve(statusHelper(status, data));
            })
            .catch((error) => {
                try {
                    if (error.response) {
                        reject(statusHelper(status, error.response));
                    } else {
                        reject(error);
                    }
                } catch (err) {
                    reject(err);
                }
            });
    });
};

var statusHelper = (status, data) => {
    if (status) {
        return {
            status: data.status,
            ...data.data,
        };
    } else {
        return data.data;
    }
};

let getServiceUrl = (baseURL) => {
    let finalURL = "";

    switch (baseURL) {
        case "auth":
            finalURL = "https://api.emotionsandallergies.com/api/v1/user/";
            break;
        case "activity":
            finalURL = "https://api.emotionsandallergies.com/api/v1/user/";
            break;
        default:
            finalURL = "http://104.131.14.223:2331/";
            break;
    }

    return finalURL;
};