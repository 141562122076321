import React from "react";
import { history } from "service/helpers";

export class MeditationLayout extends React.Component {
  render() {
    let { children } = this.props;

    return (
      <div
        className={`${
          history.location.pathname.includes("/meditation/homePage")
            ? "meditation-Layout-Bg"
            : history.location.pathname.includes("/meditation/one")
            ? "story_layout"
            : history.location.pathname.includes("/meditation/two")
            ? "story_layout"
            : history.location.pathname.includes("/meditation/three")
            ? "story_layout"
            : ""
        } container-fluid`}
      >
        <div className="row">
          <div className="col-12 py-3">{children}</div>
        </div>
      </div>
    );
  }
}
