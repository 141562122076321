import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { connect } from "react-redux";
import "./sidebar.scss";
class Sidebar extends Component {
  state = {
    navLinks: [
      {
        to: "/chef/chef-details",
        label: "Chef Profile Details",
        iconName: "dashboard",
        menuType: "chefProfileAccess",
      },
    ],
  };

  render() {
    let { navLinks } = this.state;
    let { menuOpen, accessData } = this.props;

    return (
      <div className={`left-menu ${menuOpen ? "open" : ""}`}>
        <PerfectScrollbar>
          <ul>
            {navLinks.map(
              ({ to, label, iconName, menuType }, index) =>
                accessData &&
                accessData[menuType] && (
                  <li key={index}>
                    <NavLink to={to} className="nav-link py-3">
                      <div className="sidebar-menu">
                        <div className="menu-icon text-left">
                          <i className={`icon-${iconName} fs-24`} />
                        </div>
                        <span className="sidebar-menu-desc fs-16">{label}</span>
                      </div>
                    </NavLink>
                  </li>
                )
            )}
          </ul>
        </PerfectScrollbar>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  accessData: state.commonStore.accessData,
});

export default connect(mapStateToProps, null)(Sidebar);
