import { IntroActivityOne } from "service/actionType";

const initialState = {
    formOne: {},
    formTwo: {},
    formThree: {},
  };


  const IntroActvityReducer = (state = initialState, action) => {
    switch (action.type) {
      case IntroActivityOne.formOne:
        return {
          ...state,
          formOne: action.payload,
        };
  
       
      default:
        return state;
    }
  };
  
  export default IntroActvityReducer;