import React from "react";
import "./NormalInput.scss";
import ErrorComponent from "component/common/ErrorComponent";
/**
 * Input: The Common Re-usable input across website.
 * @return {JSX.Element} The JSX Code for Input
 */
export const NormalInput = ({
  placeholder = "",
  subClassName = "",
  label = "",
  onChange,
  value = "",
  name = "",
  disabled = false,
  type = "text",
  icon,
  errorMessage = "",
  maxLength,
}) => {
  return (
    <>
      <div className={`normal-input ${subClassName ? subClassName : ""}`}>
        {label !== "" ? (
          <label className="font-weight-normal mb-1">{label}</label>
        ) : null}
        <div className="d-block">
          <input
            className="form-control w-100"
            name={name}
            type={type}
            disabled={disabled}
            value={value}
            placeholder={placeholder}
            maxLength={maxLength}
            onChange={(e) => {
              onChange({
                target: {
                  name: e.target.name,
                  value: e.target.value,
                },
              });
            }}
          />
          {icon ? <span className="icon">{icon}</span> : null}
        </div>
      </div>
      {errorMessage && errorMessage !== "" && (
        <ErrorComponent message={errorMessage} />
      )}
    </>
  );
};
