import { activityOne } from "service/actionType";

const initialState = {
  formOne: {},

  formThree: {},
};
const activityOneReducer = (state = initialState, action) => {
  switch (action.type) {
    case activityOne.formTwo:
      return {
        ...state,
        formTwo: action.payload,
      };

      case activityOne.formOne:
        return {
          ...state,
          formOne: action.payload,
        };

      case activityOne.formThree:
        return {
          ...state,
          formThree: action.payload,
        };

        case activityOne.formFive:
          return {
            ...state,
            formFive: action.payload,
          };

          case activityOne.formSix:
            return {
              ...state,
              formSix: action.payload,
            };

          case activityOne.formSeven:
            return {
              ...state,
              formSeven: action.payload,
            };
            case activityOne.formNine:
              return {
                ...state,
                formNine: action.payload,
              };

              case activityOne.formEleven:
                return {
                  ...state,
                  formEleven: action.payload,
                };
    default:
      return state;
  }
};

export default activityOneReducer;
