import React, { Component, useState } from 'react'
import 'react-rangeslider/lib/index.css'
import Slider from 'react-rangeslider'
import './RangeSlider.css'
import { useDispatch } from "react-redux";
import { setRangeSlider } from 'action/activityAct';


export function RangeSlider() {

  const dispatch = useDispatch();
  const verticalLabels = {
    25: '0',
    50: '5',
    75: '10',
    100: '15'
  }
  const formatPc = p => p + '%'

  const handleChangeVertical = value => {
    setVertical(value);
    dispatch(setRangeSlider(value))
  };

  const [vertical, setVertical] = useState(25)
  return (
    <div className='slider custom-labels'>
      <Slider
        value={vertical}
        orientation='vertical'
        labels={verticalLabels}
        //   handleLabel={vertical}
        format={formatPc}
        onChange={handleChangeVertical}
      />

    </div>
  )
}



export default RangeSlider
