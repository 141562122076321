import React from "react";
import "./style.css"


const ChatboxOne = ({src, name, handleChange, fieldValue,right,left}) => {

    // let BackgroundImage = {
    //     width: '400px',
    //     height: '300px',
    //     backgroundImage : `url{${src}}`
        
    // }
    //style={{top:top, right:right}}

    return (
        <>
        <div className="bg" style={{right: right}} >
            <form>
                <div class="form-group box-group text-center">
                   
                    <textarea onChange={handleChange} name={name} class="form-control box-control" id="exampleFormControlTextarea1" rows="4">{fieldValue}</textarea>
                </div>
            </form>
            </div>

        </>
    )
}


export default ChatboxOne;