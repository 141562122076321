import React, { useEffect, useState } from "react";
import "./Style/IntroActivityFive.scss";
import IntroActvityNav from "../common/IntroActivityNav/IntroActivitYNav";
import { Notification } from "../common/Notification/Notification";
import HeadingText from "./Images/FELICIDAD.png";
import SectionImg from "./Images/IntroAct5Img.png";
import TextIcon5 from "./Images/TextIcon5.png";
import firstDot from "../../assets/images/first-dot.png";
import secondDot from "../../assets/images/second-dot.png";
import Marking from "component/common/ImageMarker/Marker";
import { CheckBox } from "../common/CheckBox/CheckBox";
import { PopupModal } from "component/common/PopupModal/index";
import { Closurebutton } from "component/common/Closurebutton/index";
import chakra from "assets/images/chakrabg.png";
import { useHistory } from "react-router-dom";

export const IntroActivityFive = () => {
    const [popup, setPopup] = useState(false);
    const history = useHistory();
    const [opacity, setOpacity] = useState();
    const [disabled, setDisabled] = useState(false)

    const popupModal = () => {
        setPopup(true);
        setDisabled(true)
    };

    const handlePopupclose = () => {
        history.push("/home");
        setPopup(!popup);
    };

    return (
        <>
            <div
                className={`${popup ? `blur-background  IntroBackground` : `IntroBackground`
                    }`}
            >
                <IntroActvityNav
                    InProgressCar="88%"
                    InProgress="100%"
                    TextIcon={TextIcon5}
                    btnDisable = {disabled}
                />
                <div className="row mt-5">
                    <div className="col-md-2"></div>

                    <div className="col-md-4">
                        <div className="Box">
                            <div className="row text-center">
                                <div className="col-md-12">
                                    <div className="girl-spot">
                                        <CheckBox classes="introAct-girlspotone form-check-input checkbox-round" top="10%" left="27%" />

                                        <CheckBox classes="introAct-girlspottwo form-check-input checkbox-round" top="27%" left="31%" />

                                        <CheckBox
                                        classes="introAct-girlspotthree form-check-input checkbox-round"
                                            top="46%"
                                            //   Ml="80px"
                                            left="32%"
                                        />

                                        <CheckBox
                                        classes="introAct-girlspotfour form-check-input checkbox-round"
                                            top="65%"
                                            // Ml="120px"
                                            left="35%"
                                        />

                                        <CheckBox
                                        classes="introAct-girlspotFive form-check-input checkbox-round"
                                            top="90%"
                                            left="23%" />
                                    </div>

                                    <div className="boy-spot">
                                        <CheckBox classes="introAct-boyspotone form-check-input checkbox-round" top="10%" Ml="54px" />

                                        <CheckBox classes="introAct-boyspottwo form-check-input checkbox-round" top="27%" Ml="70px" />

                                        <CheckBox classes="introAct-boyspotthree form-check-input checkbox-round" top="45%" Ml="80px" />

                                        <CheckBox classes="introAct-boyspotfour form-check-input checkbox-round" top="65%" Ml="50px" />

                                        <CheckBox classes="introAct-boyspotfive form-check-input checkbox-round" top="90%" Ml="9px" />
                                    </div>
                                    <img className="SectionImg" src={SectionImg}></img>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 ml-5">
                        <h1 className="Intro-three-headingText ml-3">FELICIDAD</h1>

                        <p className="IntroText pt-4 ml-3">
                            ¿Dónde lo sentiste? Márcalo en el <br /> muñeco.{" "}
                        </p>
                    </div>
                    <div className="col-md-2"></div>
                </div>

                {/* <div className="row mt-5 text-center mb-3">
                    <div className="col-md-2"></div>
                    <div className="col-md-8"></div>
                    <div className="col-md-2 mt-0 FooterFlexButton pt-4">
                        <div className='btn-outer-border'>
                            <div className="btn-bg">
                            <img className='first-line' src={firstDot}></img>
                                <img className='second-line' src={secondDot}></img>
                                <button type="button" onClick={popupModal} className="footerButton btn btn-info" >HECHO</button>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className="row w-100">
                    <div className="col py-4">
                        <div className="d-flex justify-content-end pr-5">
                            <div className="introfive-topward">
                            <Closurebutton onClick={popupModal}>
                                <span className="introact-five-hecho-validator">hecho</span>
                            </Closurebutton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {popup ? (
                <PopupModal
                    img={chakra}
                    paraLabel="muy bien hecho! "
                    paraText="Has respondido todas 
            las preguntas"
                    popupbtnLabel="Siguiente"
                    className="popups-story-btn"
                    hideLogo="hide-animal-circle-logo"
                    hiddenPopupSpace="hide-extra-space"
                    buttonSpaces="px-0"
                    onClick={handlePopupclose}
                />
            ) : null}
        </>
    );
};
