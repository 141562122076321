import React from "react";
import { Closurebutton } from "../Closurebutton";
import "./PopupModal.scss";

export const PopupModal = ({
  img,
  circleImg,
  onClick,
  paraLabel,
  paraText,
  popupbtnLabel,
  className,
  hideLogo,
  hiddenPopupSpace,
  buttonSpaces,
  adjustButton,
  paraWidthControl,
}) => {
  return (
    <div className="story-modal-popup">
      <div className="animal-image-container" id={hideLogo}>
        <img src={circleImg} alt="Duck_B" className="Duck_B" />
      </div>
      <div className="story-Modal-inner w-100 d-flex flex-column justify-content-center align-items-center">
        <div className="py-4" id={hiddenPopupSpace} />
        <img src={img} className="img-fluid chakra-popup" alt="bigStar" />
        <p
          className="story-text-labels text-center fs-36 fw-400 m-0"
          id={paraWidthControl}
        >
          {paraLabel}
        </p>
        <p className="text-center position-relative fs-20 fw-400 has-respon">
          {paraText}
        </p>
        <div className={`${className} pt-4`} id={adjustButton}>
          <Closurebutton onClick={onClick}>
            <span className={buttonSpaces}>{popupbtnLabel}</span>
          </Closurebutton>
        </div>
      </div>
    </div>
  );
};
