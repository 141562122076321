import { storyFlow } from "service/actionType";

const initialState = {
  activeSlideOne: null,
  activeSlideTwo: null,
  activeSlideThree: null,
  activeThree: {},
  activeSix: {},
  hideButtonOne: {},
  storyOneProgress: {},
  storyTwoProgress: {},
  storyThreeProgress: {},
};

const storyFlowStore = (state = initialState, action) => {
  switch (action.type) {
    case storyFlow.activeSlideOne:
      return {
        ...state,
        activeSlideOne: action.payload,
      };
    case storyFlow.activeSlideTwo:
      return {
        ...state,
        activeSlideTwo: action.payload,
      };
    case storyFlow.activeSlideThree:
      return {
        ...state,
        activeSlideThree: action.payload,
      };
    case storyFlow.activityThree:
      return {
        ...state,
        activeThree: action.payload,
      };
    case storyFlow.activitySix:
      return {
        ...state,
        activeSix: action.payload,
      };
    case storyFlow.hideButtonOne:
      return {
        ...state,
        hideButtonOne: action.payload,
      };
    case storyFlow.storyOneProgress:
      return {
        ...state,
        storyOneProgress: action.payload,
      };
    case storyFlow.storyTwoProgress:
      return {
        ...state,
        storyTwoProgress: action.payload,
      };
    case storyFlow.storyThreeProgress:
      return {
        ...state,
        storyThreeProgress: action.payload,
      };
    default:
      return state;
  }
};

export default storyFlowStore;
