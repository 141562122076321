import React from "react"
import "./style.css"
import { useHistory } from "react-router-dom";


export const Notification = () => {
    const history = useHistory()


    return (

        <>
            <div className="outer-border">
                <div className="Modal text-center pt-4">
                    <h className="heading">muy bien hecho! </h>
                    <p className="pargraph px-3">Has respondido todas las preguntas</p>

                    <button className="btn btn-primary btn-sm congrats-btn" onClick={()=>{history.push('/story1')}}>Siguiente</button>
                </div>
            </div>
        </>
    )
}