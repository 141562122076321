import React from "react";
import "./index.scss";
/**
 * Error-Message : The Common Re-usable Error-Message across website.
 * @return {JSX.Element} The JSX Code for Error-Message
 */
const ErrorComponent = ({ message }) => (
  <div className="text-left">
    <span className="error-msg-display fs-18 text-danger">{message}</span>
  </div>
);

export default ErrorComponent;
