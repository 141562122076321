import { combineReducers } from "redux";
import commonStore from "./commonStore";
import activityOneReducer from "./activityOneReducer";
import activityTwoReducer from "./activityTwoReducer";
import activityThreeReducer from "./activityThreeReduces";
import IntroActvityOne from "./IntroActivityReducer";
import closureOneReducer from "./closureOneReducer";
import storyFlowStore from "./storyReducer";
import PopupReducer from "./PopupReducer";

export const reducers = combineReducers({
  commonStore,
  activityOneReducer,
  activityTwoReducer,
  activityThreeReducer,
  IntroActvityOne,
  storyFlowStore,
  closureOneReducer,
  PopupReducer,
});
