import React from "react";
import star from "../../../assets/images/star.png";
import "./Progressbar.scss";

export function Progressbar({ inProgress, starProgress, activityTwo }) {
    return (
        <div className={activityTwo ? "ProgressBar activity-two-progress" : "ProgressBar story2ProgressBar"}>
            <div
                className="ProgressBar__Progress"
                style={{
                    width: inProgress,
                }}
            >
                <div className="w-10">
                    {/* <img
                        src={star}
                        style={{ position: "relative", bottom: "27px", left: starProgress }}
                        className="img-fluid"
                        alt="star"
                    /> */}
                </div>
            </div>
        </div>
    );
}
