import React, {useState,useEffect } from "react";
// import "../IntroActivity/IntroActivityOne/index.css";
import './style.css'
import { ArrowLeft } from 'react-bootstrap-icons';
import { FaBeer } from 'react-icons/fa';
import { AiOutlineArrowLeft } from "react-icons/ai"
import { AiOutlineArrowRight } from "react-icons/ai"
import { AiFillHome } from "react-icons/ai"
import rectangleImg from "../../IntroActivity/Images/Rectangle\ 2803.png"
import ScrollingCar from "../../IntroActivity/Images/image\ 30\ \(1\).png"
import LfArrow from "../../IntroActivity/Images/Leftarrow.png"
import RfArrow from "../../IntroActivity/Images/RightArrow.png"
import homeIcon from "../../IntroActivity/Images/homeIntro.png"
import StarIcon from "../../IntroActivity/Images/starIcon.png"
// import TextIcon from "../../IntroActivity/Images/5.png"
import Box from "../../IntroActivity/Images/Vector\ \(5\).png"
import HeadingText from "../../IntroActivity/Images/HACEMOS\ NUEVOS\ ANIMOS.png"
import { useHistory } from "react-router-dom";
import {useParams} from "react-router-dom"
import {Progressbar} from "component/common/ProgressBar/ProgressBar"




const IntroActvityNav = ({TextIcon, InProgress, InProgressCar,btnDisable}) => {
    const history = useHistory()
    const id = useParams()
    let url = window.location.href
    let path = url.split("/")
    let numberValue = path[3]
    let Stringnumber = numberValue.split("IntroActivity")
    let IntroActivityNumber = parseInt(Stringnumber[1]) + 1
    let DecreamentIntroActivityNumber = parseInt(Stringnumber[1]) - 1
      

    console.log("IntroActivity")

    // saving data in local storage   
    const [data, setData] = useState('0%')

    useEffect(() => {
        
        if( numberValue === 'IntroActivity1'){
            setData("0%")
        }
        if( numberValue === 'IntroActivity2' ){
            setData("25%")
        }
        if( numberValue === 'IntroActivity3' ){
            setData("50%")
        }
        if( numberValue === 'IntroActivity4' ){
            setData("75%")
        }
        if( numberValue === 'IntroActivity5' ){
            setData("100%")
        }
        
        localStorage.setItem('ourdata', data)

    } , [ numberValue, data])

    console.log('data',  data)

    


    return (
        <>
            <div className="">
                <div className="row text-center pt-4">

                    <div className="col-md-3">
                        {/* <img className="LeftBox" src={Box}></img> */}
                        <div className="IntroBoxParent" >
                        <button 
                        disabled= {btnDisable}
                        onClick={
                            ()=>{
                                IntroActivityNumber>2? history.push("/IntroActivity"+DecreamentIntroActivityNumber): history.push("/home")} }
                        type="button" className="Leftbox btn btn-info"><img src={LfArrow} alt="leftArrow"></img></button>
                       </div>

                       <div className="IntroBoxParent">
                           
                        <button  disabled = {btnDisable} onClick={()=>{IntroActivityNumber<6? history.push("/IntroActivity"+IntroActivityNumber): history.push("/IntroActivity"+IntroActivityNumber-1)}} type="button" class="Leftbox btn btn-info"><img src={RfArrow}></img></button>
                        </div>
                       
                    </div>
                    <div className="col-md-7 mt-3">
                        <Progressbar
                        inProgress = {InProgress}
                        />
                        {/* <img className="RectangleImge" src={rectangleImg}></img> */}
                        <img className="ScrollingImg" src={ScrollingCar}
                        style={{left: InProgressCar}} alt="car"
                        ></img>
                        <div>
                            <div className="flex">
                                <img className="IntroStarIcon" src={StarIcon} alt="star"></img>
                                <img className="IntroTextIcon" src={TextIcon} alt="text"></img>

                            </div>
                        </div>


                    </div>
                    <div className="col-md-2">
                        {/* <img className="RightBox" src={Box}></img> */}
                        <div className="IntroBoxParent">

                        <button disabled= {IntroActivityNumber<6? true :btnDisable} type="button" onClick={()=>{history.push("/home")}} className="Rightbox btn btn-info"><img src={homeIcon}></img></button>
                        </div>

                    </div>
                </div>
                {/* <div className="row text-center mt-5 pb-5">
                    <div className="col-md-12">
                        <img src={HeadingText}></img>
                    </div>
                </div> */}
                </div>
        </>
    )
};


export default IntroActvityNav;