import { duration } from "moment";
import mediMouse from "../../../assets/svg/mediMouse.svg";
import mediMonkey from "../../../assets/svg/mediMonkey.svg";
import mediDuck from "../../../assets/svg/mediDuck.svg";

export const homeCard = [
  {
    id: "01",
    btntxt: "Empezamos",
    starpic: require("assets/images/star.png"),
    homeBg: require("assets/images/carvibingmouse.png"),
    ispercenatge0: false,
    ispercenatge1: true,
    ispercenatge2: true,
    ispercenatge3: true,
    ispercenatge4: true,
    homecardlink: "/IntroActivity1",
  },
  {
    id: "02",
    btntxt: "¡SIN QUeso, por favor!",
    starpic: require("assets/images/star.png"),
    homeBg: require("assets/images/threesiblingmouse.png"),
    ispercenatge0: true,
    ispercenatge1: false,
    ispercenatge2: true,
    ispercenatge3: true,
    ispercenatge4: true,
    homecardlink: "/story-one",
  },
  {
    id: "03",
    btntxt: "¡Sin frutos secos, por favor!",
    starpic: require("assets/images/star.png"),
    homeBg: require("assets/images/secosporfavor.png"),
    ispercenatge0: true,
    ispercenatge1: true,
    ispercenatge2: false,
    ispercenatge3: true,
    ispercenatge4: true,
    homecardlink: "/story-two",
  },
  {
    id: "04",
    btntxt: "¡Estanque sin gluten por favor!",
    starpic: require("assets/images/star.png"),
    homeBg: require("assets/images/glutenporfavor.png"),
    ispercenatge0: true,
    ispercenatge1: true,
    ispercenatge2: true,
    ispercenatge3: false,
    ispercenatge4: true,
    homecardlink: "/story-three",
  },
  {
    id: "05",
    btntxt: "Nos relajamos",
    homeBg: require("assets/images/backwardjumpmouse.png"),
    ispercenatge0: true,
    ispercenatge1: true,
    ispercenatge2: true,
    ispercenatge3: true,
    ispercenatge4: true,
    homecardlink: "/meditation/homePage",
  },
  {
    id: "06",
    btntxt: "Jugamos",
    homeBg: require("assets/images/gamingsection.png"),
    ispercenatge0: true,
    ispercenatge1: true,
    ispercenatge2: true,
    ispercenatge3: true,
    ispercenatge4: true,
    homecardlink: "/games/gamesection",
  },
  {
    id: "07",
    btntxt: "Clausura",
    starpic: require("assets/images/star.png"),
    homeBg: require("assets/images/feedbacksection.png"),
    ispercenatge0: true,
    ispercenatge1: true,
    ispercenatge2: true,
    ispercenatge3: true,
    ispercenatge4: false,
    homecardlink: "/closure",
  },
];

export const closureSection = [
  {
    id: "01",
    closureheading:
      "Para acabar nos gustaría saber si estas sesiones te han ayudado o no. Podrías por favor, ¿responder estas preguntas? Como verás puedes escoger entre si no has aprendido nada, solo un poquito o mucho.",
    closureparaone: "1. ¿Cuánto crees que te ha ayudado en algo este programa?",
    closureparatwo:
      "2. ¿Cuánto crees que has aprendido a entender mejor tus emociones con este programa?",
    closureparathree:
      "3. ¿Cuánto crees que te ha ayudado a sentirte más tranquilo con tu alergia?",
    closureparafour:
      "4.	¿Cuánto crees que te ha ayudado a encontrar estrategias para sentirte mejor cuando vas a alguna fiesta o en el colegio?",
    closureparafive:
      "5.	Con los cuentos explicados en el colegio a tus compañeros, ¿Cuánto te has sentido más comprendido por ellos?",
    closureparasix:
      "6. Si tuvieras un amigo con alergias, ¿cuanto le recomendarías que hiciera este programa?",
    closureparaseven:
      "7. ¿Te lo has pasado bien participando en este programa?",
    closureparaeight: "8. ¿Cuanto hay que NO te haya gustado de este programa?",
    closureparanine: "9.	¿Nos puedes explicar qué NO te ha gustado y por qué?",
    closureparanten:
      "10. ¿Hay algo que no te haya ayudado? ¿Nos lo puedes explicar? ",
    closureparaeleven: "11. ¿Cuánto te ha gustado participar en este programa?",
  },
];

export const thinkerLabel = [
  {
    id: "01",
    thinkTxt: "¡Sin queso, for favor!",
  },
];

export const meditationHomePage = [
  {
    id: "01",
    cardBg: require("assets/images/meditationcardbg.png"),
    hideCard: false,
    mediTxt: "Aprendemos a respirar con Alicia",
    mediAnimals: mediMouse,
    meditationLink: "/meditation/one",
  },
  {
    id: "02",
    cardBg: require("assets/images/meditationcardbg.png"),
    hideCard: false,
    mediTxt: "Aprendemos a relajarnos con Bruno",
    mediAnimals: mediMonkey,
    meditationLink: "/meditation/two",
  },
  {
    id: "03",
    cardBg: require("assets/images/meditationcardbg.png"),
    hideCard: true,
    mediTxt: "Respiramos y nos relajamos con Carlota",
    mediAnimals: mediDuck,
    meditationLink: "/meditation/three",
  },
];
