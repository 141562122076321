import React, { Component } from "react";
import "./navbar.scss";
import { logout } from "service/utilities";
import logo from "assets/svg/logo.svg";
import { Modal } from "react-bootstrap";
import { NormalButton } from "component/common/NormalButton";
import { NormalInput } from "../NormalInput";
import validate from "service/validation";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

export class NavbarClass extends Component {
  state = {
    active: false,
    isModalVisible: false,
    passwordDetails: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    currentPasswordVisible: false,
    newPasswordVisible: false,
    reEnterpasswordVisible: false,
    errors: {},
    userDetails: {},
  };
  //handle dropdown
  handleDropdown = () => {
    if (!this.state.active) {
      document.addEventListener(
        "click",
        this.handleOutsideClickForProfile,
        false
      );
    } else {
      document.removeEventListener(
        "click",
        this.handleOutsideClickForProfile,
        false
      );
    }
    this.setState((prevState) => ({
      active: !prevState.active,
    }));
  };

  //handle dropdown when clicked outside
  handleOutsideClickForProfile = (e) => {
    if (this.node != null) {
      if (this.node.contains(e.target)) {
        return;
      }
    }
    this.handleDropdown();
  };

  //handle logout
  handleLogout = () => {
    logout();
  };

  //handle Cancel
  handleCancel = () => {
    this.setState({
      isButtonClicked: false,
      passwordDetails: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
      isModalVisible: false,
    });
  };

  //handle Change
  handleChange = ({ target: { name, value } }) => {
    let { passwordDetails, errors } = this.state;

    let tempObj = { ...passwordDetails };

    const tempErrors = { ...errors };

    tempErrors[name] = undefined;
    tempObj[name] = value;
    this.setState({
      passwordDetails: { ...tempObj },
      errors: { ...tempErrors },
    });
  };

  //validation Rules
  validationRules = () => {
    let passwordValidation = {
      // format: {
      //     pattern:
      //         /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!_#%*?&])[A-Za-z\d@_#$!%*?&]*$/,
      //     flags: "i",
      //     message:
      //         "^Password must contain at least one uppercase letter, one lowercase letter, one number and one special character",
      // },
      length: {
        minimum: 7,
        tooShort: "^Must be atleast 8 characters",
        maximum: 15,
        tooLong: "^Must contain less than 12 character",
      },
    };
    return {
      oldPassword: {
        presence: {
          allowEmpty: false,
          message: "^Current Password is required",
        },
      },
      newPassword: {
        presence: {
          allowEmpty: false,
          message: "^New Password is required",
        },
        ...passwordValidation,
      },
      confirmPassword: {
        presence: {
          allowEmpty: false,
          message: "^Confirm password is required",
        },
        equality: {
          attribute: "newPassword",
          message: "^Both password must match",
          comparator: function (v1, v2) {
            return JSON.stringify(v1) === JSON.stringify(v2);
          },
        },
      },
    };
  };

  //validate Fields
  validateFields = (data) => {
    const fieldInvalidList = validate(data, this.validationRules());

    if (fieldInvalidList !== undefined) {
      const errors = { ...fieldInvalidList };
      this.setState({
        errors: { ...errors },
      });
    }

    return !fieldInvalidList;
  };

  //handle Submit
  handleSubmit = () => {
    let { passwordDetails, userDetails } = this.state;
    let body = {
      adminId: userDetails._id,
      ...passwordDetails,
    };
    if (!this.validateFields(body)) return;
    this.setState({ isButtonClicked: true });
    delete body.confirmPassword;
  };

  render() {
    let {
      active,
      isModalVisible,
      passwordDetails,
      errors,
      isButtonClicked,
      currentPasswordVisible,
      newPasswordVisible,
      reEnterpasswordVisible,
      userDetails,
    } = this.state;

    let { oldPassword, newPassword, confirmPassword } = passwordDetails;

    let { name = "" } = userDetails || {};

    return (
      <React.Fragment>
        <div className="navbar fixed-top px-4 py-3">
          <div className="d-flex justify-content-between w-100 h-100">
            <div className="">
              <img src={logo} alt="homechefLogo" />
            </div>
            <div className="d-flex justify-content-end w-100 align-items-center">
              <div className="mr-4 d-flex justify-content-center align-items-center">
                <span className="border-radius-50 profile-name-card">
                  {name ? name.substring(0, 1) : "?"}
                </span>
              </div>
              <div
                className="cursor-pointer profile profile-dropdown mr-3"
                onClick={this.handleDropdown}
              >
                <div className="d-flex align-items-center">
                  <p className="mb-0 fs-16 line-height-18 fw-500 pr-3 text-white">
                    {name}
                  </p>
                  <i className="icon-down-arrow down-icon text-white" />
                </div>
                <ul className={`dropdown ${active ? "active" : ""}`}>
                  <li
                    className="cursor-pointer"
                    onClick={() =>
                      this.setState({ isModalVisible: !isModalVisible })
                    }
                  >
                    <i className="icon-settings fs-20 mr-3" />
                    Change Password
                  </li>
                  <li
                    onClick={() => this.handleLogout()}
                    className="cursor-pointer"
                  >
                    <i className="icon-logout fs-20 mr-3" />
                    Logout
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={isModalVisible}
          className="change-password"
          size="md"
          centered
          onHide={() => this.handleCancel()}
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Header closeButton id="contained-modal-title-vcenter">
            <Modal.Title>Change Password</Modal.Title>
          </Modal.Header>

          <div className="popup-password">
            <div className="model_password_input">
              <NormalInput
                label="Current Password"
                onChange={this.handleChange}
                type={currentPasswordVisible ? "text" : "password"}
                name="oldPassword"
                value={oldPassword}
                errorMessage={errors.oldPassword ? errors.oldPassword[0] : null}
              />
              <NormalInput
                label="New Password"
                onChange={this.handleChange}
                name="newPassword"
                value={newPassword}
                type={newPasswordVisible ? "text" : "password"}
                errorMessage={errors.newPassword ? errors.newPassword[0] : null}
              />
              <NormalInput
                label="Confirm New Password"
                onChange={this.handleChange}
                name="confirmPassword"
                type={reEnterpasswordVisible ? "text" : "password"}
                value={confirmPassword}
                errorMessage={
                  errors.confirmPassword ? errors.confirmPassword[0] : null
                }
              />
            </div>
            <div className="d-flex justify-content-between align-items-center mt-3">
              <div className="d-flex">
                <NormalButton
                  label="Cancel"
                  viewButtonHeader
                  className="mr-3"
                  disabled={isButtonClicked}
                  onClick={() => this.handleCancel()}
                />
                <NormalButton
                  label="Save"
                  viewButtonColorHeader
                  onClick={() => this.handleSubmit()}
                  disabled={isButtonClicked}
                />
              </div>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};
export const Navbar = connect(null, mapDispatchToProps)(NavbarClass);
