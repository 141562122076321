import { activityData } from "service/actionType";
import { activityApi } from "../service/apiVariables";
export const setRangeSlider =
  (data) =>
    (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        dispatch({
          type: activityData.rangeSlider,
          payload: data,
        });
      });
    };

export const sendActivityData =
  (body) =>
    (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {
        api({ ...activityApi.sendData, body })
          .then(({ message, token, data }) => {
            resolve(data);
          })
          .catch(({ message }) => {
            reject(Toast({ type: "error", message }));
          });
      });
    };
