import React from "react";
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Homecard } from "component/common/Homecard/index";
import { Logoutbtn } from "component/common/Logoutbtn/index";
import Allergy from "../../assets/images/allergyEmotions.png";
import Logout from "../../assets/svg/logoutbtn.svg";

export const HomePages = () => {
  const history = useHistory();

  // set an unique id
  const customId = "";

  toast.configure();

  // to popup toaster
  const Notifier = () => {
    // Calling toast method by passing string
    toast("¡Cierre de sesión con éxito!", {
      toastId: customId,
    });
  };

  // to logout homepage
  const LogoutHome = () => {
    Notifier();
    setTimeout(() => {
      history.push("/login");
    }, 6000);
  };

  return (
    // home page container starts here
    <div className="container-fluid">
      <div className="row w-100">
        <div className="col-lg-12 col-md-12 col-12 pt-4 pb-5">
          <div className="d-flex justify-content-between">
            <div className="pl-3">
              <img src={Allergy} className="img-fluid" alt="allergy" />
            </div>
            <div>
              <Logoutbtn onClick={LogoutHome}>
                <img src={Logout} className="img-fluid" alt="logout" />
              </Logoutbtn>
            </div>
          </div>
        </div>
      </div>
      <div className="row w-100">
        <div className="col-12 pb-3">
          {/* card for the homepage */}
          <Homecard />
        </div>
      </div>
    </div>
  );
  // homepage container ends here
};
