/* eslint-disable react/react-in-jsx-scope */
import React from "react";
import BootstrapButton from "react-bootstrap/Button";
import "./HomeButtondisabled.scss";

/**
 * Button: The Common Re-usable Button across website.
 * @return {JSX.Element} The JSX Code for Button
 */

export function HomeButtondisabled({ children, onClick }) {
  return (
    <div className="px-0">
      <div className="home-border">
        <div className="innerhome-border">
          <BootstrapButton
            id="homebtns"
            className="home-violet-button mx-auto d-block w-100"
            onClick={onClick}
            disabled
          >
            <span className="homepage-btN-TexT">{children}</span>
          </BootstrapButton>
        </div>
      </div>
    </div>
  );
}
