/* eslint-disable react/react-in-jsx-scope */

/**
 * Button: The Common Re-usable Button across website.
 * @return {JSX.Element} The JSX Code for Button
 */

import React, { useEffect, useState } from "react";
import { HomeButton } from "component/common/HomeButton/index";
import { HomeButtondisabled } from "component/common/HomeButtondisabled/index";
import { homeCard } from "component/common/MockData/MockData";
import { useHistory } from "react-router-dom";
import Play from "../../../assets/images/playbutton.jpg";
import Lock from "../../../assets/images/lock.png";
import "./Homecard.scss";

export function Homecard() {
  // get all percentage data from local-storage
  const getIntropercentage = localStorage.getItem("ourdata");
  const storyOnecount = localStorage.getItem("storyone-count");
  const storyTwocount = localStorage.getItem("storytwo-count");
  const storyThreecount = localStorage.getItem("storythree-count");
  const closureLevel = localStorage.getItem("closure-level");

  // default percentage for initial progress
  const zerothPercentage = "0%";

  // state to update button type
  const [btnType1, setBtnType1] = useState();

  // state to update button type
  const [btnType2, setBtnType2] = useState();

  // state to update button type
  const [btnType3, setBtnType3] = useState();

  // state to enable or disable button
  const [btn1, setBtn1] = useState(false);

  // state to enable or disable button
  const [btn2, setBtn2] = useState(false);

  // state to enable or disable button
  const [btn3, setBtn3] = useState(false);

  // function to disable buttons
  const DisableBtns = () => {
    if (getIntropercentage === "100%") {
      setBtnType1(Play);
      setBtn1(true);
    } else {
      setBtnType1(Lock);
    }
    if (storyOnecount === "100%") {
      setBtnType2(Play);
      setBtn2(true);
    } else {
      setBtnType2(Lock);
    }
    if (storyTwocount === "100%") {
      setBtnType3(Play);
      setBtn3(true);
    } else {
      setBtnType3(Lock);
    }
  };

  // state to initialize disable function
  const [allow, setAllow] = useState(() => DisableBtns());

  // get data from story-three flow last slide
  const unlockStore = localStorage.getItem("storythree-count-unlock");

  // render disable functionality
  useEffect(() => {
    setAllow(allow);
  }, [allow]);

  // to navigate routed pages
  const history = useHistory();

  return (
    <React.Fragment>
      {unlockStore ? (
        <div className="row pl-5">
          {/* card in home page to show progress starts here */}
          {homeCard.map((item, index) => {
            return (
              <div className="col-lg-3 col-md-4 col-12 h-100 the-homePage-card pb-3">
                {item.starpic && (
                  <div className="starPic">
                    <div className="completion-container">
                      <div
                        className={
                          item.ispercenatge0 ? "hide-progress" : "show-progress"
                        }
                      >
                        <p className="completion-level fs-14 fw-700">
                          {getIntropercentage
                            ? getIntropercentage
                            : zerothPercentage}
                        </p>
                      </div>
                      <div
                        className={
                          item.ispercenatge1 ? "hide-progress" : "show-progress"
                        }
                      >
                        <p className="completion-level fs-14 fw-700">
                          {storyOnecount ? storyOnecount : zerothPercentage}
                        </p>
                      </div>
                      <div
                        className={
                          item.ispercenatge2 ? "hide-progress" : "show-progress"
                        }
                      >
                        <p className="completion-level fs-14 fw-700">
                          {storyTwocount ? storyTwocount : zerothPercentage}
                        </p>
                      </div>
                      <div
                        className={
                          item.ispercenatge3 ? "hide-progress" : "show-progress"
                        }
                      >
                        <p className="completion-level fs-14 fw-700">
                          {storyThreecount ? storyThreecount : zerothPercentage}
                        </p>
                      </div>
                      <div
                        className={
                          item.ispercenatge4 ? "hide-progress" : "show-progress"
                        }
                      >
                        <p className="completion-level fs-14 fw-700">
                          {closureLevel ? closureLevel : zerothPercentage}
                        </p>
                      </div>
                    </div>
                    <img
                      key={item.id}
                      src={`${item.starpic}`}
                      className="star"
                      alt=""
                    />
                  </div>
                )}
                <div className={`The-home-Card`}>
                  <div className="position-relative">
                    <img
                      src={item.homeBg}
                      alt="homeBackground"
                      className="w-100 bg-image"
                    />
                    <div className="play-button">
                      {item.id === "01" && (
                        <img
                          key={item.id}
                          src={Play}
                          className="img-fluid mx-auto d-block videoButton"
                          alt="playerbutton"
                        />
                      )}
                      {item.id === "02" && (
                        <img
                          key={item.id}
                          src={Play}
                          className="img-fluid mx-auto d-block videoButton"
                          alt="playerbutton"
                        />
                      )}
                      {item.id === "03" && (
                        <img
                          key={item.id}
                          src={Play}
                          className="img-fluid mx-auto d-block videoButton"
                          alt="playerbutton"
                        />
                      )}
                      {item.id === "04" && (
                        <img
                          key={item.id}
                          src={Play}
                          className="img-fluid mx-auto d-block videoButton"
                          alt="playerbutton"
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="homeCard-button-container mx-auto">
                  <div className="home-card-Button w-100">
                    {item.id === "01" && (
                      <React.Fragment>
                        <HomeButton
                          onClick={() => history.push(`${item.homecardlink}`)}
                          key={index}
                        >
                          <span className="fs-24 homebutton-Text">
                            {item.btntxt}
                          </span>
                        </HomeButton>
                      </React.Fragment>
                    )}
                    {item.id === "02" && (
                      <React.Fragment>
                        <HomeButton
                          onClick={() => history.push(`${item.homecardlink}`)}
                          key={index}
                        >
                          <span className="fs-24 homebutton-Text">
                            {item.btntxt}
                          </span>
                        </HomeButton>
                      </React.Fragment>
                    )}
                    {item.id === "03" && (
                      <React.Fragment>
                        <HomeButton
                          onClick={() => history.push(`${item.homecardlink}`)}
                          key={index}
                        >
                          <span className="fs-24 homebutton-Text">
                            {item.btntxt}
                          </span>
                        </HomeButton>
                      </React.Fragment>
                    )}
                    {item.id === "04" && (
                      <React.Fragment>
                        <HomeButton
                          onClick={() => history.push(`${item.homecardlink}`)}
                          key={index}
                        >
                          <span className="fs-24 homebutton-Text">
                            {item.btntxt}
                          </span>
                        </HomeButton>
                      </React.Fragment>
                    )}
                    {item.id === "05" && (
                      <React.Fragment>
                        <HomeButton
                          onClick={() => history.push(`${item.homecardlink}`)}
                          key={index}
                        >
                          <span className="fs-24 homebutton-Text">
                            {item.btntxt}
                          </span>
                        </HomeButton>
                      </React.Fragment>
                    )}
                    {item.id === "06" && (
                      <React.Fragment>
                        <HomeButton
                          onClick={() => history.push(`${item.homecardlink}`)}
                          key={index}
                        >
                          <span className="fs-24 homebutton-Text">
                            {item.btntxt}
                          </span>
                        </HomeButton>
                      </React.Fragment>
                    )}
                    {item.id === "07" && (
                      <React.Fragment>
                        <HomeButton
                          onClick={() => history.push(`${item.homecardlink}`)}
                          key={index}
                        >
                          <span className="fs-24 homebutton-Text">
                            {item.btntxt}
                          </span>
                        </HomeButton>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
          {/* card in home page to show progress ends here */}
        </div>
      ) : (
        <div className="row pl-5">
          {/* card in home page to show progress starts here */}
          {homeCard.map((item, index) => {
            return (
              <div className="col-lg-3 col-md-4 col-12 h-100 the-homePage-card pb-3">
                {item.starpic && (
                  <div className="starPic">
                    <div className="completion-container">
                      <div
                        className={
                          item.ispercenatge0 ? "hide-progress" : "show-progress"
                        }
                      >
                        <p className="completion-level fs-14 fw-700">
                          {getIntropercentage
                            ? getIntropercentage
                            : zerothPercentage}
                        </p>
                      </div>
                      <div
                        className={
                          item.ispercenatge1 ? "hide-progress" : "show-progress"
                        }
                      >
                        <p className="completion-level fs-14 fw-700">
                          {storyOnecount ? storyOnecount : zerothPercentage}
                        </p>
                      </div>
                      <div
                        className={
                          item.ispercenatge2 ? "hide-progress" : "show-progress"
                        }
                      >
                        <p className="completion-level fs-14 fw-700">
                          {storyTwocount ? storyTwocount : zerothPercentage}
                        </p>
                      </div>
                      <div
                        className={
                          item.ispercenatge3 ? "hide-progress" : "show-progress"
                        }
                      >
                        <p className="completion-level fs-14 fw-700">
                          {storyThreecount ? storyThreecount : zerothPercentage}
                        </p>
                      </div>
                      <div
                        className={
                          item.ispercenatge4 ? "hide-progress" : "show-progress"
                        }
                      >
                        <p className="completion-level fs-14 fw-700">
                          {closureLevel ? closureLevel : zerothPercentage}
                        </p>
                      </div>
                    </div>
                    <img
                      key={item.id}
                      src={`${item.starpic}`}
                      className="star"
                      alt=""
                    />
                  </div>
                )}
                <div className={`The-home-Card`}>
                  <div className="position-relative">
                    <img
                      src={item.homeBg}
                      alt="homeBackground"
                      className="w-100 bg-image"
                    />
                    <div className="play-button">
                      {item.id === "01" && (
                        <img
                          key={item.id}
                          src={`${getIntropercentage === "100%" ? Play : Play}`}
                          className="img-fluid mx-auto d-block videoButton"
                          alt="playerbutton"
                        />
                      )}
                      {item.id === "02" && (
                        <img
                          key={item.id}
                          src={btnType1}
                          className="img-fluid mx-auto d-block videoButton"
                          alt="playerbutton"
                        />
                      )}
                      {item.id === "03" && (
                        <img
                          key={item.id}
                          src={btnType2}
                          className="img-fluid mx-auto d-block videoButton"
                          alt="playerbutton"
                        />
                      )}
                      {item.id === "04" && (
                        <img
                          key={item.id}
                          src={btnType3}
                          className="img-fluid mx-auto d-block videoButton"
                          alt="playerbutton"
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="homeCard-button-container mx-auto">
                  <div className="home-card-Button w-100">
                    {item.id === "01" && (
                      <React.Fragment>
                        {zerothPercentage ? (
                          <HomeButton
                            onClick={() => history.push(`${item.homecardlink}`)}
                            key={index}
                          >
                            <span className="fs-24 homebutton-Text">
                              {item.btntxt}
                            </span>
                          </HomeButton>
                        ) : (
                          <HomeButtondisabled>
                            <span className="fs-24 homebutton-Text">
                              {item.btntxt}
                            </span>
                          </HomeButtondisabled>
                        )}
                      </React.Fragment>
                    )}
                    {item.id === "02" && (
                      <React.Fragment>
                        {btn1 ? (
                          <HomeButton
                            onClick={() => history.push(`${item.homecardlink}`)}
                            key={index}
                          >
                            <span className="fs-24 homebutton-Text">
                              {item.btntxt}
                            </span>
                          </HomeButton>
                        ) : (
                          <HomeButtondisabled>
                            <span className="fs-24 homebutton-Text">
                              {item.btntxt}
                            </span>
                          </HomeButtondisabled>
                        )}
                      </React.Fragment>
                    )}
                    {item.id === "03" && (
                      <React.Fragment>
                        {btn2 ? (
                          <HomeButton
                            onClick={() => history.push(`${item.homecardlink}`)}
                            key={index}
                          >
                            <span className="fs-24 homebutton-Text">
                              {item.btntxt}
                            </span>
                          </HomeButton>
                        ) : (
                          <HomeButtondisabled>
                            <span className="fs-24 homebutton-Text">
                              {item.btntxt}
                            </span>
                          </HomeButtondisabled>
                        )}
                      </React.Fragment>
                    )}
                    {item.id === "04" && (
                      <React.Fragment>
                        {btn3 ? (
                          <HomeButton
                            onClick={() => history.push(`${item.homecardlink}`)}
                            key={index}
                          >
                            <span className="fs-24 homebutton-Text">
                              {item.btntxt}
                            </span>
                          </HomeButton>
                        ) : (
                          <HomeButtondisabled>
                            <span className="fs-24 homebutton-Text">
                              {item.btntxt}
                            </span>
                          </HomeButtondisabled>
                        )}
                      </React.Fragment>
                    )}
                    {item.id === "05" && (
                      <React.Fragment>
                        {!storyThreecount ? (
                          <HomeButton
                            onClick={() => history.push(`${item.homecardlink}`)}
                            key={index}
                          >
                            <span className="fs-24 homebutton-Text">
                              {item.btntxt}
                            </span>
                          </HomeButton>
                        ) : (
                          <HomeButton
                            onClick={() => history.push(`${item.homecardlink}`)}
                            key={index}
                          >
                            <span className="fs-24 homebutton-Text">
                              {item.btntxt}
                            </span>
                          </HomeButton>
                        )}
                      </React.Fragment>
                    )}
                    {item.id === "06" && (
                      <React.Fragment>
                        {!storyThreecount ? (
                          <HomeButton
                            onClick={() => history.push(`${item.homecardlink}`)}
                            key={index}
                          >
                            <span className="fs-24 homebutton-Text">
                              {item.btntxt}
                            </span>
                          </HomeButton>
                        ) : (
                          <HomeButton
                            onClick={() => history.push(`${item.homecardlink}`)}
                            key={index}
                          >
                            <span className="fs-24 homebutton-Text">
                              {item.btntxt}
                            </span>
                          </HomeButton>
                        )}
                      </React.Fragment>
                    )}
                    {item.id === "07" && (
                      <React.Fragment>
                        {!storyThreecount ? (
                          <HomeButton
                            onClick={() => history.push(`${item.homecardlink}`)}
                            key={index}
                          >
                            <span className="fs-24 homebutton-Text">
                              {item.btntxt}
                            </span>
                          </HomeButton>
                        ) : (
                          <HomeButton
                            onClick={() => history.push(`${item.homecardlink}`)}
                            key={index}
                          >
                            <span className="fs-24 homebutton-Text">
                              {item.btntxt}
                            </span>
                          </HomeButton>
                        )}
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
          {/* card in home page to show progress ends here */}
        </div>
      )}
    </React.Fragment>
  );
}
