import React from "react";
import { history } from "service/helpers";
import NavBar from "component/common/Activity/ActivityTwoNav";


export class StoryLayout extends React.Component {
  render() {
    let { children } = this.props;

    return (
      <div className='container-fluid story_layout'>
        {history.location.pathname.includes("/story-one/activity") ?
          <div className="row">
            <div className="col-md-12">
              <NavBar
                limit={11}
                splitKeyword={"activity"}
                urlNum={4}
                activityTwo={true}
              />
            </div>
          </div>
          : ''}
        {history.location.pathname.includes("/story-two/activity") ?
          <div className="row">
            <div className="col-md-12">
              <NavBar
                limit={15}
                splitKeyword={"activity"}
                urlNum={4}
                activityTwo={true}
              />
            </div>
          </div>
          : ''}
        {children}
      </div>
    );
  }
}

