//auth Api
export const authApi = {
  loginApi: {
    api: "signIn",
    method: "post",
    baseURL: "auth",
  },
};


export const activityApi = {
  sendData: {
    api: "activityEmails",
    method: "post",
    baseURL: "activity",
  },
};
