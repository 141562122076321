import React, { Component } from "react";
import { Storyhomebtn } from 'component/common/Storyhomebtn/index';
import home from '../assets/images/home.png';
import logout from '../assets/images/logououtbtn.png';
import NavBar from "component/common/Activity/ActivityTwoNav"
import TextIcon5 from "component/IntroActivity/Images/TextIcon5.png"
import { history } from "service/helpers";


export class ActivityLayout extends Component {
  render() {
    let { children } = this.props;
    history.location.pathname.includes("/story-one/activity")

    return (
      <div className=' activity_Layout IntroBackground' style={{position:'absolute',width:'100%'}}>
        {history.location.pathname.includes("/story-one/activity") ?
          <div className="row">
            <div className="col-md-12">
              <NavBar
                limit={11}
                splitKeyword={"activity"}
                urlNum={4}
                activityTwo={true}
              />
            </div>

          </div>
          : <div className="row">
            <div className="col-12 py-4">
              <div class="d-flex justify-content-end">
                <div className="pr-5">
                  <Storyhomebtn>
                    <img src={home} className="img-fluid home_Icon" alt='homeicon' />
                    <img src={logout} className="img-fluid" alt='home' />
                  </Storyhomebtn>
                </div>
              </div>
            </div>
          </div>}
        {children}
      </div>
    );
  }
}

