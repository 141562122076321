import BannerOne from "assets/images/Story/StoryTwoAssets/secondImage.png";
import B3 from "assets/images/Story/StoryTwoAssets/2.png";
import B4 from "assets/images/Story/StoryTwoAssets/4.png";
import B5 from "assets/images/Story/StoryTwoAssets/5.png";
import B6 from "assets/images/Story/StoryTwoAssets/6.png";
import B8 from "assets/images/Story/StoryTwoAssets/8.png";
import B9 from "assets/images/Story/StoryTwoAssets/9.png";
import B10 from "assets/images/Story/StoryTwoAssets/10.png";
import B11 from "assets/images/Story/StoryTwoAssets/11.png";
import B12 from "assets/images/Story/StoryTwoAssets/12.png";
import B13 from "assets/images/Story/StoryTwoAssets/13.png";
import B14 from "assets/images/Story/StoryTwoAssets/14.png";
import B15 from "assets/images/Story/StoryTwoAssets/15.png";
import B16 from "assets/images/Story/StoryTwoAssets/16.png";
import B17 from "assets/images/Story/StoryTwoAssets/17.png";
import B0 from "assets/images/Story/StoryTwoAssets/intro.png";
import storyThreeBannerOne from "assets/images/Story3-images/storyThreeBannerOne.png";
import PondDuck from "../assets/images/Story3-images/pondducks.png";
import sadducks from "../assets/images/Story3-images/sadducks.png";
import thinkerduck from "../assets/images/Story3-images/thinkerduck.png";
import happymother from "../assets/images/Story3-images/happymotherduck.png";
import dineducks from "../assets/images/Story3-images/dinnerducks.png";
import painterducks from "../assets/images/Story3-images/painterducks.png";
import masterduck from "../assets/images/Story3-images/duckpaintsgirl.png";
import littlegirl from "../assets/images/Story3-images/littlegirl.png";
import sadpainter from "../assets/images/Story3-images/sadpainterduck.png";
import duckpaintgirl from "../assets/images/Story3-images/painterduc&girl.png";
import lonelyduck from "../assets/images/Story3-images/verylonelyduck.png";
import romanticduck from "../assets/images/Story3-images/romanticduck.png";
import duckhumanfriends from "../assets/images/Story3-images/duck&humankids.png";
import vacationducks from "../assets/images/Story3-images/duckvacation.png";
import duckfriends from "../assets/images/Story3-images/duckfriends.png";

//import activity two images
// import TextIcon1 from "component/IntroActivity/Images/"
import TextIcon1 from "assets/images/Story/ActivityTwo/One/TextIcon1.png";
import TextIcon2 from "assets/images/Story/ActivityTwo/One/TextIcon2.png";
import TextIcon3 from "assets/images/Story/ActivityTwo/One/TextIcon3.png";
import TextIcon4 from "assets/images/Story/ActivityTwo/One/TextIcon4.png";
import TextIcon5 from "assets/images/Story/ActivityTwo/One/TextIcon5.png";
import TextIcon6 from "assets/images/Story/ActivityTwo/One/TextIcon6.png";
import TextIcon7 from "assets/images/Story/ActivityTwo/One/TextIcon7.png";
import TextIcon8 from "assets/images/Story/ActivityTwo/One/TextIcon8.png";
import TextIcon9 from "assets/images/Story/ActivityTwo/One/TextIcon9.png";
import TextIcon10 from "assets/images/Story/ActivityTwo/One/TextIcon10.png";
import TextIcon11 from "assets/images/Story/ActivityTwo/One/TextIcon11.png";

// Story2 TextIcon
import S2TextIcon1 from "assets/images/Story/ActivityTwo/One/S2TextIcon1.png";
import S2TextIcon2 from "assets/images/Story/ActivityTwo/One/S2TextIcon2.png";
import S2TextIcon3 from "assets/images/Story/ActivityTwo/One/S2TextIcon3.png";
import S2TextIcon4 from "assets/images/Story/ActivityTwo/One/S2TextIcon4.png";
import S2TextIcon5 from "assets/images/Story/ActivityTwo/One/S2TextIcon5.png";
import S2TextIcon6 from "assets/images/Story/ActivityTwo/One/S2TextIcon6.png";
import S2TextIcon7 from "assets/images/Story/ActivityTwo/One/S2TextIcon7.png";
import S2TextIcon8 from "assets/images/Story/ActivityTwo/One/S2TextIcon8.png";
import S2TextIcon9 from "assets/images/Story/ActivityTwo/One/S2TextIcon9.png";
import S2TextIcon10 from "assets/images/Story/ActivityTwo/One/S2TextIcon10.png";
import S2TextIcon11 from "assets/images/Story/ActivityTwo/One/S2TextIcon11.png";
import S2TextIcon12 from "assets/images/Story/ActivityTwo/One/S2TextIcon12.png";
import S2TextIcon13 from "assets/images/Story/ActivityTwo/One/S2TextIcon13.png";
import S2TextIcon14 from "assets/images/Story/ActivityTwo/One/S2TextIcon14.png";
import S2TextIcon15 from "assets/images/Story/ActivityTwo/One/S2TextIcon15.png";

export const storyoneContent = [
  {
    id: 1,
    story1cardtxt: null,
    music: require("../assets/audio/New Recording 30.m4a"),
  },
  {
    id: 2,
    story2cardtxt: [
      "Qué alegría tuvieron el papá y la mamá de",
      "Alicia cuando ella nació.",
      "Por fin podían abrazarla y estar con ella.",
      " “¡Qué ratoncita tan bonita!”",
      "- exclamaron los dos muy felices.",
    ],
    music: require("../assets/audio/2.m4a"),
  },
  {
    id: 3,
    story3cardtxt: [
      "Pasó el tiempo y Alicia, como todos los otros",
      "ratoncitos, fue creciendo. Así se convirtió en",
      "una ratoncita muy lista quien le gustaba",
      "cantar, bailar y dibujar.",
    ],
    music: require("../assets/audio/3.m4a"),
  },
  {
    id: 4,
    story4cardtxt: [
      "Sin embargo, Alicia era una ratoncita muy",
      "especial ya que había una cosa que no podía",
      "hacer igual que los otros ratoncitos: comer",
      "queso.",
      "Cada vez que comía queso se ponía muy roja",
      "y le dolía la barriga.",
    ],
    music: require("../assets/audio/4.m4a"),
  },
  {
    id: 5,
    story5cardtxt: [
      "Al principio su papá y su mamá no",
      "comprendían",
      "lo que le pasaba.",
      "“¡A todos los ratoncitos les encanta el queso!”",
      "- exclamaron sorprendidos.",
      "“¿Qué le pasa a nuestra hija?”",
      "- se preguntaban preocupados e incluso un",
      "poco asustados.",
      "Lo probaron todo: puré de queso con",
      "miel, queso con",
      "patatas... Pero Alicia se ponía roja, toda roja.",
    ],
    music: require("../assets/audio/5.m4a"),
  },
  {
    id: 6,
    story6cardtxt: [
      "El doctor les explicó que Alicia tenía alergia",
      "al queso",
      "y que no podía comer nada que tuviera",
      "queso",
      "porque le hacía daño a su barriga.",
      "“¡Qué cosa más rara!” - exclamaron.",
      "“¿Cómo vamos a hacerlo? ¿Qué le vamos a",
      "dar para",
      "comer?” - se preguntaron",
    ],
    music: require("../assets/audio/6.m4a"),
  },
  {
    id: 7,
    story7cardtxt: [
      "Poco a poco probaron a darle de comer",
      "otras cosas sin queso.",
      "Le dieron: leche de arroz, yogures de soja,",
      "galletas sin queso y frutas.",
      "",
      "Todo eso parecía gustarle mucho a Alicia y",
      "además no le hacía daño.",
      " Y así de esta manera",
      "Alicia fue creciendo muy fuerte y feliz.",
      "",
    ],
    music: require("../assets/audio/7.m4a"),
  },
  {
    id: 8,
    story8cardtxt: [
      "Cuando Alicia cumplió los tres años",
      "empezó a ir al colegio como todos los otros ratoncitos.",
      " Y al igual que todos Alicia estaba un poco asustada y a la vez",
      "",
      "contenta porque iba a conocer a nuevos amigos y aprender",
      "",
      "muchas cosas.",
    ],
    music: require("../assets/audio/8.m4a"),
  },
  {
    id: 9,
    story9cardtxt: [
      "Pero como Alicia no podía comer queso",
      "como los otros ratoncitos siempre llevaba la comida que su mamá",
      "",
      "",
      "le preparaba en una cajita y se la comía",
      "separada de los demás.",
      "En esos momentos, Alicia siempre se",
      "sentía un poco triste y sola.",
      "",
    ],
    music: require("../assets/audio/9.m4a"),
  },
  {
    id: 10,
    story10cardtxt: [
      "Cuando se celebraba una fiesta de cumpleaños,",
      "Alicia nunca podía participar ya que los pasteles de",
      "los otros ratoncitos estaban hechos de queso.",
      "",
      "“¿Por qué no puedo yo comer queso?” - se",
      "preguntaba triste e incluso a veces enfadada",
      "",
      "Sus compañeros la miraban y preguntaban extrañados:",
      "",
      "“¿por qué no come como todos?”",
      "“Alicia no puede comer queso porque le daña a su",
      "barriguita” - explicaba la maestra.",
    ],
    music: require("../assets/audio/10.m4a"),
  },
  {
    id: 11,
    story11cardtxt: [
      "Poco a poco sus compañeros empezaron a",
      "comprender incluso algún ratoncito le traía pasteles sin queso",
      "",
      "para que ella también pudiera participar.",
      "El curso fue pasando y Alicia se sentía cada vez",
      "más tranquila y contenta. Sabía que era diferente",
      "porque no podía comer como los otros, pero si",
      "podía hacer el resto de actividades igual que sus amigos",
      "",
    ],
    music: require("../assets/audio/11.m4a"),
  },
  {
    id: 12,
    story12cardtxt: [
      "Llegó el fn de curso y decidieron preparar una gran",
      "fiesta para celebrarlo.",
      "",
      "Invitaron a todas las familias.",
      "Habían ensayado un espectáculo durante todo el",
      "año y estaban impacientes,",
      " querían enseñar a todos lo",
      "mucho que habían aprendido.",
      "",
    ],
    music: require("../assets/audio/12.m4a"),
  },
  {
    id: 13,
    story13cardtxt: [
      "Todos lo hicieron muy bien.",
      "Llegó el turno de Alicia que había preparado un",
      "baile.",
      "“¡Bravo!” - exclamaron sus compañeros y todos los",
      "espectadores.",
      "“¡Qué bien baila Alicia!” – decían admirados.",
    ],
    music: require("../assets/audio/13.m4a"),
  },
  {
    id: 14,
    story14cardtxt: [
      "El espectáculo había sido un éxito.",
      "Estaban todos muy contentos.",
      "De repente, el ratón pastelero, que había estado",
      "contemplando el espectáculo, se levantó y dijo: “¡Hay",
      "que celebrarlo! ¡Voy a preparar una gran merienda para",
      "",
      "todos con un gran pastel!",
      "",
    ],
    music: require("../assets/audio/14.m4a"),
  },
  {
    id: 15,
    story15cardtxt: [
      "Los ratoncitos se miraron y con una gran sonrisa",
      "exclamaron al unísono: “¡Sí un pastel! Pero…",
      "¡Sin queso, por favor!”",
    ],
    music: require("../assets/audio/15.m4a"),
  },
];

export const storyTwoData = [
  {
    activeslide: 0,
    inProgress: "6%",
    starProgress: "0%",
    banner: B0,
    storyCommonRight: false,
    text: null,
    music: require("../assets/audio/New Recording 30.m4a"),
  },
  {
    activeslide: 1,
    text: "El monito Bruno vivía en el circo con su mamá, su papá y todos los monitos trapecistas. Bruno era un monito como todos los demás, le gustaba jugar en el trapecio con sus amigos y dar un sinfín de volteretas.",
    inProgress: "12%",
    starProgress: "6%",
    banner: BannerOne,
    storyCommonRight: false,
    storySlidefirst: "storySlidefirst",
    music: require("../assets/audio/New Recording 31.m4a"),
  },
  {
    activeslide: 2,
    text: "Pero Bruno era también un monito muy especial porque no podía comer cacahuetes. Cuando era pequeñito su mamá y su papá se dieron un buen susto al comprobar que Bruno se ponía muy enfermo y le costaba respirar cada vez que le daban cacahuetes.",
    inProgress: "18%",
    starProgress: "12%",
    banner: B3,
    storyCommonRight: false,
    storySlideone: "storySlideone",
    music: require("../assets/audio/New Recording 32.m4a"),
  },
  {
    activeslide: 3,
    text: "La doctora les dijo que Bruno tenía alergia a los cacahuetes y a los frutos secos. Les explicó, que por este motivo, Bruno no podía comer ningún tipo de fruto seco, como: las nueces, las almendras, los piñones, los anacardos y las avellanas; ni tampoco comida que llevara estos frutos secos, porque se pondría poner muy enfermo. También les entregó un botiquín de emergencia para que, en el caso de que Bruno tuviera una reacción alérgica fuerte, como un choque anafiláctico, pudieran darle una medicina.",
    inProgress: "24%",
    starProgress: "18%",
    banner: B4,
    storyCommonRight: false,
    storySlideone: "storySlideone",
    music: require("../assets/audio/New Recording 33.m4a"),
  },
  {
    activeslide: 4,
    text: "Al principio todos se asustaron un poco. ¡A todos los monitos les gustan los cacahuetes!– exclamaron su papá y su mamá. -¿Cómo vamos a hacer para protegerlo?- se preguntaron inquietos. En el circo, todos juntos, decidieron no comer más frutos secos. Así Bruno se sentiría igual que todos los otros monitos y al mismo tiempo evitarían un accidente.",
    inProgress: "36%",
    starProgress: "30%",
    storyCommonRight: true,
    banner: B5,
    rightContent: true,
    storySlidetwo: "storySlidetwo",
    music: require("../assets/audio/New Recording 34.m4a"),
  },
  {
    activeslide: 5,
    text: "Así, Bruno fue creciendo como todos los demás monitos protegido de cualquier reacción alérgica. En el colegio del circo, todos los monitos estaban aprendiendo muy rápido sus acrobacias. Un día, el profesor decidió que ya estaban preparados para su primera actuación. -¡Yupi! ¡Vamos a hacer un espectáculo!– exclamaron los monitos muy contentos.",
    inProgress: "42%",
    starProgress: "36%",
    storyCommonRight: false,
    storySlideone: "storySlideone",
    banner: B6,
    music: require("../assets/audio/New Recording 35.m4a"),
  },
  {
    activeslide: 6,
    text: "El día del espectáculo estaban todos un poco nerviosos. Pero como habían trabajado mucho, cuando les tocó su turno hicieron todas sus acrobacias perfectamente. El público estaba impresionado. -¡Bravo!– gritaban todos entusiasmados",
    inProgress: "48%",
    starProgress: "42%",
    storyCommonRight: false,
    banner: B8,
    storySlideone: "storySlideone",
    music: require("../assets/audio/New Recording 36.m4a"),
  },
  {
    activeslide: 7,
    text: "En el público había un grupo de monitos de la misma edad que Bruno. Al final del espectáculo quisieron ir a felicitarlos. -¡Bravo! ¡Sois unos acróbatas estupendos! Hemos traído estos caramelos para vosotros– les dijeron los monitos espectadores. -¡Muchas gracias!– respondieron Bruno y sus amigos. Y sin pensárselo dos veces, Bruno cogió un caramelo azul – era su color preferido – y se lo comió.",
    inProgress: "54%",
    starProgress: "48%",
    storyCommonRight: true,
    storySlidetwo: "storySlidetwo",
    banner: B9,
    rightContent: true,
    music: require("../assets/audio/New Recording 37.m4a"),
  },
  {
    activeslide: 8,
    text: "Enseguida se dio cuenta de que algo le pasaba y empezó a sentirse muy mal. Le picaba la boca y empezaba a respirar con dificultad. -Id a buscar a mi mamá, creo que tengo un problema...– les pidió Bruno a sus amigos. Su mamá llegó enseguida con su botiquín de emergencia y le pichó en la pierna como les había enseñado la doctora. Bruno se sintió enseguida mejor, aun así se fueron al hospital para vigilar que la reacción alérgica había terminado del todo.",
    inProgress: "60%",
    starProgress: "54%",
    storyCommonRight: false,
    banner: B10,
    storySlideone: "storySlideone",
    music: require("../assets/audio/New Recording 38.m4a"),
  },
  {
    activeslide: 9,
    text: "En el hospital Bruno explicó a su mamá lo que había pasado. -Lo siento mamá, pero era un caramelo azul, y ya sabes que es mi color preferido. No pensé que pudiera haber un problema con un caramelo- dijo Bruno tristemente. -Ya lo sé cariño, pero resulta que dentro del caramelo había un cacahuete. Tenemos que buscar una solución para que los monitos que no te conocen no te den frutos secos. Pero no te preocupes mi monito, la encontraremos– le respondió su mamá dándole un abrazo muy fuerte.",
    inProgress: "66%",
    starProgress: "60%",
    storyCommonRight: true,
    storySlidetwo: "storySlidetwo",
    rightContent: true,
    banner: B11,
    music: require("../assets/audio/New Recording 39.m4a"),
  },
  {
    activeslide: 10,
    text: "De vuelta a casa la mamá de Bruno se sentó en el suelo y empezó a pensar: “¿Que podría hacer? ¿Cómo evitar otros accidentes? ¿Cómo hacer saber a los otros que Bruno no debe comer frutos secos?” La mamá pensó y pensó hasta que algo se le ocurrió. -¡Ya lo tengo!- exclamó. Y se pasó toda la noche, cortando, dibujando, coloreando y pegando.",
    inProgress: "72%",
    starProgress: "66%",
    storyCommonRight: false,
    storySlideone: "storySlideone",
    banner: B12,
    music: require("../assets/audio/New Recording 40.m4a"),
  },
  {
    activeslide: 11,
    text: "Por la mañana, la mamá le dio a Bruno un botón pin en el que había dibujado la carita de Bruno y, alrededor del dibujo, había escrito: “¡Sin frutos secos por favor!”. Su mamá le explicó que si lo llevaba siempre puesto, los otros monitos lo verían e irían con cuidado antes de darle algo de comer. A Bruno le gustó mucho. Se sentía especial y, al mismo tiempo, se sentía un poco más tranquilo. A partir de ahora los otros monitos no le darían de comer sin vigilar.",
    inProgress: "78%",
    starProgress: "72%",
    storyCommonRight: false,
    storySlideone: "storySlideone",
    banner: B13,
    music: require("../assets/audio/New Recording 41.m4a"),
  },
  {
    activeslide: 12,
    text: "El mismo día, por la tarde, Bruno y sus amigos tenían otro espectáculo. Bruno estaba un poco nervioso pero sabía que el botón pin le iba a ayudar. El espectáculo fue un éxito de nuevo.",
    text2:
      "El público estaba entusiasmado con ellos. -¡Bravo!– exclamaba el público. Al igual que la última vez, al final del espectáculo algunos espectadores se acercaron a ellos para felicitarlos.",
    inProgress: "84%",
    starProgress: "78%",
    storyCommonRight: false,
    storySlidethree: "storySlidethree",
    betweenContent: true,
    banner: B14,
    music: require("../assets/audio/New Recording 42.m4a"),
  },
  {
    activeslide: 13,
    text: "Esta vez, los espectadores enseguida vieron el botón pin y le preguntaron a Bruno qué era. Bruno les explicó que tenía alergia a los frutos secos y que no debía comer nada que tuviera frutos secos. También les explicó que el botón pin servía para avisar a los otros de que tenía una alergia y evitar así accidentes. -¡Qué buena idea! ¿Quién te lo ha hecho?- le preguntaron. -¡Mi mamá!- respondió Bruno muy orgulloso. -Tenemos unos plátanos, ¿te apetece uno?– le preguntaron. -Sí, gracias. ¡Me encantan los plátanos!– respondió Bruno muy contento.",
    inProgress: "90%",
    starProgress: "84%",
    storyCommonRight: true,
    storySlidetwo: "storySlidetwo",
    rightContent: true,
    banner: B15,
    music: require("../assets/audio/New Recording 43.m4a"),
  },
  {
    activeslide: 14,
    text: "En ese momento, un monito que lo había oído todo, se acercó y le dijo a Bruno: -Oh, me encanta tu botón pin. A mi no me gusta nada el brócoli, ¿crees que tu mamá me podría hacer uno para mí que diga: “¡Sin brócoli, por favor!”? -¡Ja, ja, ja!- rieron todos. Y con una gran sonrisa llena de orgullo Bruno respondió: -¡Lo siento, pero creo que mi mamá hará los botón pin solo para los monitos especiales que tienen alergias como yo!-",
    inProgress: "96%",
    starProgress: "90%",
    storyCommonRight: true,
    storySlidetwo: "storySlidetwo",
    rightContent: true,
    banner: B16,
    music: require("../assets/audio/New Recording 44.m4a"),
  },
  {
    activeslide: 15,
    inProgress: "100%",
    starProgress: "96%",
    storyCommonRight: false,
    banner: B17,
    text: null,
    top: 60,
    music: require("../assets/audio/Opt3.mp3"),
  },
];

export const storyOneActivityData = [
  {
    id: 1,
    S1TextIcon: "1/11",
    InProgressCar: "6%",
    InProgress: "9.11%",
  },
  {
    id: 2,
    S1TextIcon: "2/11",
    InProgressCar: "11%",
    InProgress: "18.11%",
  },
  {
    id: 3,
    S1TextIcon: "3/11",
    InProgressCar: "21%",
    InProgress: "27.11%",
  },
  {
    id: 4,
    S1TextIcon: "4/11",
    InProgressCar: "30%",
    InProgress: "36.11%",
  },
  {
    id: 5,
    S1TextIcon: "5/11",
    InProgressCar: "39%",
    InProgress: "45.11%",
  },
  {
    id: 6,
    S1TextIcon: "6/11",
    InProgressCar: "48%",
    InProgress: "54.11%",
  },
  {
    id: 7,
    S1TextIcon: "7/11",
    InProgressCar: "57%",
    InProgress: "63.11%",
  },
  {
    id: 8,
    S1TextIcon: "8/11",
    InProgressCar: "66%",
    InProgress: "72.11%",
    right: "0%",
  },
  {
    id: 9,
    S1TextIcon: "9/11",
    InProgressCar: "75%",
    InProgress: "81.11%",
  },
  {
    id: 10,
    S1TextIcon: "10/11",
    InProgressCar: "84%",
    InProgress: "89.11%",
  },
  {
    id: 11,
    S1TextIcon: "11/11",
    InProgressCar: "88%",
    InProgress: "100%",
  },
];

export const storyTwoActivityData = [
  {
    id: 1,
    TextIcon: S2TextIcon1,
    InProgressCar: "4%",
    InProgress: "6%",
  },
  {
    id: 2,
    TextIcon: S2TextIcon2,
    InProgressCar: "10%",
    InProgress: "12%",
  },
  {
    id: 3,
    TextIcon: S2TextIcon3,
    InProgressCar: "17%",
    InProgress: "20%",
  },
  {
    id: 4,
    TextIcon: S2TextIcon4,
    InProgressCar: "22%",
    InProgress: "24%",
  },
  {
    id: 5,
    TextIcon: S2TextIcon5,
    InProgressCar: "25%",
    InProgress: "27%",
  },
  {
    id: 6,
    TextIcon: S2TextIcon6,
    InProgressCar: "28%",
    InProgress: "30%",
  },
  {
    id: 7,
    TextIcon: S2TextIcon7,
    InProgressCar: "30%",
    InProgress: "33%",
  },
  {
    id: 8,
    TextIcon: S2TextIcon8,
    InProgressCar: "34%",
    InProgress: "38%",
  },
  {
    id: 9,
    TextIcon: S2TextIcon9,
    InProgressCar: "42%",
    InProgress: "45.45%",
  },
  {
    id: 10,
    TextIcon: S2TextIcon10,
    InProgressCar: "50%",
    InProgress: "55%",
  },
  {
    id: 11,
    TextIcon: S2TextIcon11,
    InProgressCar: "65%",
    InProgress: "69%",
  },
  {
    id: 12,
    TextIcon: S2TextIcon12,
    InProgressCar: "70%",
    InProgress: "76%",
  },
  {
    id: 13,
    TextIcon: S2TextIcon13,
    InProgressCar: "75%",
    InProgress: "82%",
  },
  {
    id: 14,
    TextIcon: S2TextIcon14,
    InProgressCar: "83%",
    InProgress: "90%",
  },
  {
    id: 15,
    TextIcon: S2TextIcon15,
    InProgressCar: "88%",
    InProgress: "100%",
  },
];

export const storyThreeData = [
  {
    activeslide: 0,
    inProgress: "6%",
    starProgress: "2%",
    banner: storyThreeBannerOne,
    storyCommonRight: false,
    text: null,
    music: require("../assets/audio/New Recording 30.m4a"),
  },
  {
    activeslide: 1,
    text: [
      "Carlota vivía en el pequeño estanque /del parque. A Carlota le gustaba mucho /nadar y pintar con sus amigos. Siempre /llevaba su bañador rosa preferido.",
    ],
    inProgress: "6%",
    starProgress: "2%",
    banner: PondDuck,
    storyCommonRight: false,
    storyOne: "storyOne",
    music: require("../assets/audio/New Recording 61.m4a"),
  },
  {
    activeslide: 2,
    text: [
      "Pero Carlota se sentía a veces muy cansada y le /dolía la barriga, así que paraba de nadar y se /sentaba debajo del árbol mientras contemplaba a /sus amigos que jugaban sin parar.",
    ],
    inProgress: "12%",
    starProgress: "7%",
    banner: sadducks,
    storyCommonRight: true,
    rightContent: true,
    storyTwo: "storyTwo",
    music: require("../assets/audio/New Recording 62.m4a"),
  },
  {
    activeslide: 3,
    text: [
      "Pero su mamá estaba cada vez más preocupada. /No entendía porqué su pequeña /Carlota se cansaba tanto. Además, a menudo no terminaba su comida, /y se quejaba de dolor de barriga.",
    ],
    textNext: [
      "Al final decidió llevarla al doctor. Le hicieron algunas /pruebas, los resultados salieron bien, pero el doctor /sospechó que Carlota podía tener una intolerancia al /gluten. Les recomendó de no comer más gluten durante /un tiempo para ver si así Carlota empezaba a sentirse /mejor.",
    ],
    inProgress: "18%",
    starProgress: "14%",
    banner: thinkerduck,
    storyCommonRight: false,
    storyThree: "storyThree",
    music: require("../assets/audio/New Recording 63.m4a"),
  },
  {
    activeslide: 4,
    text: [
      "Al salir de la consulta del médico Carlota le /preguntó a su mamá: - ¿Qué es el gluten?-",
    ],
    text2: [
      "- El gluten es una proteína que se encuentra en /los cereales como el trigo,/ el centeno, la cebada, /la espelta, el kamut y la avena. Así hay gluten /en el pan que comemos, en la pasta, las galletas y /los pasteles por ejemplo– le explicó la-mamá.  ¡Oh, no! Pero a mí me encanta el pan y la pasta /mamá – respondió Carlota un poco enfadada, no le gustaba nada /el plan del doctor. - Sí cariño lo entiendo, pero /creo que a lo mejor podremos comprar pan y /pasta sin gluten, voy a buscarlo, no te /preocupes. Te imaginas si encontramos la solución a tu cansancio, sería estupendo– /respondió la mamá intentando consolarla.//- Mmmm… – dijo Carlota nada convencida",
    ],
    inProgress: "24%",
    starProgress: "20%",
    storyCommonRight: false,
    storyFourone: "storyFourone",
    storyFourtwo: "storyFourtwo",
    banner: happymother,
    betweenContent: true,
    music: require("../assets/audio/New Recording 64.m4a"),
  },
  {
    activeslide: 5,
    text: [
      "Así siguieron el consejo del doctor y empezaron a comer /sin gluten. Como la mamá había dicho, encontraron pan, /pasta, pasteles y galletas sin gluten. Tenían un gusto un /poco diferente pero Carlota aceptó comerlos sin rechistar.",
    ],
    text2: [
      "Al cabo de unas semanas /Carlota se dio cuenta de que /podía nadar más rato sin /cansarse y tenía menos dolores /de barriga.",
    ],
    inProgress: "30%",
    starProgress: "26%",
    storyCommonRight: false,
    banner: dineducks,
    betweenContent: true,
    storyFiveone: "storyFiveone",
    storyFivetwo: "storyFivetwo",
    music: require("../assets/audio/New Recording 65.m4a"),
  },
  {
    activeslide: 6,
    text: [
      "Sin embargo, cuando los niños /y las niñas venían al estanque a /jugar con ellos y les daban pan, /Carlota se quedaba ahora a un /lado. Sabía que no podía comer /ese pan porque llevaba gluten./Tristemente los miraba y /pensaba que era injusto.",
    ],
    text2: [
      "Decidió que llevaría su pincel al /parque así cuando llegaran los /niños y las niñas se pondría a /dibujar y se sentiría menos triste.",
    ],
    inProgress: "36%",
    starProgress: "32%",
    storyCommonRight: false,
    banner: painterducks,
    betweenContent: true,
    storySixone: "storySixone",
    storySixtwo: "storySixtwo",
    music: require("../assets/audio/New Recording 66.m4a"),
  },
  {
    activeslide: 7,
    text: [
      "Un día mientras pintaba, una /niña se acercó a ella./ -¡Hola! ¿Cómo te llamas? – le /preguntó sonriendo. /-Carlota – le respondió muy /sorprendida. /-Yo me llamo Emma ¿Por /qué ya no vienes a jugar con /nosotros?- le preguntó la niña",
    ],
    text2: [
      "Carlota estaba cada vez más /sorprendida y tímidamente le /explicó que tenía intolerancia al /gluten y que por eso ya no podía /comer el pan. Emma, mirándola /con sus grandes ojos, le dijo : - /sabes, yo tengo alergia al trigo.",
    ],
    inProgress: "47%",
    starProgress: "41%",
    storyCommonRight: false,
    banner: masterduck,
    betweenContent: true,
    storyEightone: "storyEightone",
    storyEighttwo: "storyEighttwo",
    music: require("../assets/audio/New Recording 67.m4a"),
  },
  {
    activeslide: 8,
    text: ["- ¿ Qué quieres decir? – le", "preguntó Carlota"],
    inProgress: "53%",
    starProgress: "47%",
    banner: littlegirl,
    rightContent: false,
    storyNine: "storyNine",
    music: require("../assets/audio/New Recording 68.m4a"),
  },
  {
    activeslide: 9,
    text: [
      "-Bueno, al igual que a ti el trigo /me hace daño. Pero si tienes /alergia quiere decir que no /puedes comer ni un trocito de /algo que lleve trigo porque /entonces me pongo /rápidamente muy enferma y /tienen que darme una /medicina para que me sienta mejor – le /explicó Emma.",
    ],
    inProgress: "60%",
    starProgress: "54%",
    storyCommonRight: false,
    banner: sadpainter,
    rightContent: true,
    storyTen: "storyTen",
    music: require("../assets/audio/New Recording 69.m4a"),
  },
  {
    activeslide: 10,
    text: [
      "-Ah, yo no tengo que tomar una medicina /rápidamente si como algo de gluten. Pero /debo evitarlo porque si tomo durante unos /días empiezo a sentirme muy cansada y me /duele la barriga- explicó Carlota./-Sí, la reacción es diferente, pero no /podemos comer gluten ninguna de las dos./Seguramente nos sentimos las dos igual de /tristes cuando no podemos comer como los /otros ¿verdad? – le preguntó Emma.",
    ],
    inProgress: "66%",
    starProgress: "61%",
    storyCommonRight: false,
    banner: duckpaintgirl,
    storyEleven: "storyEleven",
    music: require("../assets/audio/New Recording 70.m4a"),
  },
  {
    activeslide: 11,
    text: [
      "Carlota, haciendo que sí con su cabeza, /le preguntó: – ¿quieres ser mi amiga?/-¡Claro! – le respondió Emma dándole /un abrazo –¡Nos vemos mañana!",
    ],
    inProgress: "72%",
    starProgress: "67%",
    storyCommonRight: false,
    banner: lonelyduck,
    rightContent: true,
    storyTwelve: "storyTwelve",
    music: require("../assets/audio/New Recording 71.m4a"),
  },
  {
    activeslide: 12,
    text: [
      "Cuando Emma llegó al parque ese día estaba emocionada. Enseguida se fue al estanque a buscar a /Carlota./Carlota la estaba esperando, se dieron un abrazo y se sentaron juntas para merendar dor",
    ],
    text3: [
      "-Te he traído mis galletas preferidas /sin gluten, ¿quieres probar? – le /preguntó Emma. -¡Claro! ¡Seguro que /éstas las puedo tomar!- /respondió Carlota comiéndose una. – /¡Están deliciosas! –continuó Carlota /con una carcajada.",
    ],
    inProgress: "78%",
    starProgress: "73%",
    storyCommonRight: false,
    banner: romanticduck,
    rightContent: false,
    storyThirteen: "storyThirteen",
    music: require("../assets/audio/New Recording 72.m4a"),
  },
  {
    activeslide: 13,
    text: [
      "No se habían dado cuenta de que los otros niños, niñas y patitos las estaban mirando. No /comprendían porque estaban sentadas separadas del grupo. Axel se acercó a ellas y les preguntó /porqué no iban con ellos. Carlota y Emma le explicaron que tenían intolerancia al gluten y alergia al /trigo y que por eso no podían comer gluten.",
    ],
    text3: [
      "Axel los llamó a todos y les explicó /lo que pasaba. Se le había /ocurrido algo y dijo: - mañana /haremos un picnic sin gluten, ¿qué /os parece? -¡Sí, buena idea!- /respondieron contentos. Entonces /se fueron corriendo a casa para /prepararlo todo. Axel además /tenía otro plan y se pasó toda la /noche preparando una sorpresa /para Carlota.",
    ],
    inProgress: "84%",
    starProgress: "79%",
    storyCommonRight: false,
    banner: duckhumanfriends,
    rightContent: false,
    storyThirteen: "storyThirteen",
    music: require("../assets/audio/New Recording 73.m4a"),
  },
  {
    activeslide: 14,
    text: [
      "¡Qué bien se lo estaban pasando /todos en el picnic! Habían traído muchas frutas de colores y /Emma les ofreció un paquete de sus galletas /preferidas.",
    ],
    text2: [
      "Carlota y Emma estaban muy contentas, les parecía tan divertido poder compartir una merienda todos juntos./De repente Axel se levantó, llevaba /algo escondido en la espalda. Se fue corriendo al borde del /estanque y clavó algo de madera en el suelo. Era como un /cartel.",
    ],
    inProgress: "90%",
    starProgress: "85%",
    storyCommonRight: false,
    banner: vacationducks,
    betweenContent: true,
    storyFiveteenone: "storyFiveteenone",
    storyFiveteentwo: "storyFiveteentwo",
    music: require("../assets/audio/New Recording 74.m4a"),
  },
  {
    activeslide: 15,
    text: ["-¿Qué haces?- le /preguntaron todos /sorprendidos"],
    text2: [
      "-¡Venid! ¡A ver /qué os parece /es una idea que /he tenido! –/exclamó Axel.",
    ],
    text3: [
      "Todos se acercaron y vieron un /cartel donde Axel había escrito: /“¡Estanque sin gluten, por favor!” ",
    ],
    inProgress: "100%",
    starProgress: "94%",
    threeContent: true,
    banner: duckfriends,
    storySixteenone: "storySixteenone",
    storySixteentwo: "storySixteentwo",
    storySixteenthree: "storySixteenthree",
    music: require("../assets/audio/New Recording 75.m4a"),
  },
];
