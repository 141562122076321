import React from "react";
import CanvasDraw from "react-canvas-draw";
import './style.css'


const DrawingBox = ({ firstCanvas }) => {
    return (
        <>
            <CanvasDraw
                className="DrawingBox"
                style={{ border: '15px solid brown', borderRadius: '20px' }}
                brushRadius={1}
                lazyRadius={20}
                canvasHeight={400}
                hideGrid={true}
                ref={firstCanvas}
               
                
               

            >

            </CanvasDraw>
        </>
    )
}


export default DrawingBox;